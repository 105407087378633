import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
    generate1095CPart2Report,
    GENERATE_1095C_PART2_REPORT_ACTION,
} from 'actions/payroll/generate1095CPart2Report';
import Select from 'components/Select';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { onChange } from 'utilities/forms';
import { getYears } from 'utilities/year';

const years = getYears(() => 1).map((x) => ({ name: `${x}` }));

type IGenerate1095CPart2ReportModal = {
    close: (year?: string, month?: number) => void;
    initialYear: string;
};

const Generate1095CPart2ReportModal = ({ close, initialYear }: IGenerate1095CPart2ReportModal) => {
    const dispatch = useThunkDispatch();
    const { teamId } = useTeamProps();
    const { isGeneratingReport } = useSelector((state: AppStore) => ({
        isGeneratingReport: hasApiActivity(state, GENERATE_1095C_PART2_REPORT_ACTION),
    }));

    const [year, setYear] = useState(initialYear);

    useEffect(() => {
        setYear(initialYear);
    }, [initialYear]);

    const generateReport = async () => {
        await dispatch(generate1095CPart2Report(teamId, +year));
        close();
    };

    const cancel = () => close();

    return (
        <Dialog fullWidth maxWidth="xs" onClose={cancel} open>
            <DialogTitle>Generate 1095-C Part 2 Report</DialogTitle>
            <DialogContent dividers>
                <Typography className="mb-3">
                    Select the year you would like to generate the report for. The report will be
                    prepared and added to the Files tab once it is ready. You will get an email
                    notification when the file is available.
                </Typography>
                <Stack gap={2} pt={1}>
                    <Select
                        data-cy="year"
                        items={years}
                        label="Year"
                        name="selectedYear"
                        onChange={onChange(setYear)}
                        optionText="name"
                        optionValue="name"
                        value={year}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel} variant="text">
                    Close
                </Button>
                <LoadingButton
                    data-cy="generate-button"
                    loading={isGeneratingReport}
                    onClick={generateReport}
                    variant="text"
                >
                    Generate
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default hot(module)(Generate1095CPart2ReportModal);
