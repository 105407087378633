import { applyCalculationForPathwayBlueprint } from 'actions/pathwayBlueprint/applyCalculationForPathwayBlueprint';
import { calculatePathwayUser } from 'actions/pathwayBlueprint/calculatePathwayUser';
import { getPathwayUserProfileInfo } from 'actions/pathwayBlueprint/getPathwayUserProfileInfo';
import {
    CalculatePathwayBlueprint,
    EditPathway,
    ViewPathwayBlueprints,
} from 'api/generated/permissions';
import ActionButtons, { IActionButtonItems } from 'components/ActionButtons';
import ProfileAttribute from 'components/ProfileAttribute';
import useContributionProps from 'hooks/useContributionProps';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import CustomProjectedReimbursementModal from 'pages/pathwayBlueprint/CustomProjectedReimbursementModal';
import CustomProjectedWageUpModal from 'pages/pathwayBlueprint/CustomProjectedWageUpModal';
import {
    openCustomReimbursementModal,
    openCustomWageUpModal,
} from 'pages/pathwayBlueprint/pathwayCustomProjectedModalActions';
import ProjectedContributionContent from 'pages/profile/contributionProfileAttributes/ProjectedContributionContent';
import { PerMonthLabel } from 'pages/profile/UserBudgetProfileAttribute';
import React, { useCallback, useMemo } from 'react';
import { ButtonProps } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { isAdvisorSelector, isRhSelector } from 'selectors/role';
import { hasValue } from 'utilities';
import { formatCurrency } from 'utilities/format';
import {
    isCustomReimbursementsAllowed,
    isIchraPathwayType,
    showActiveContributionByTeamState,
} from 'utilities/pathways';

const ProjectedContributionProfileAttribute = () => {
    const dispatch = useThunkDispatch();
    const {
        activePathwayBlueprintId,
        canCalculatePathwayBlueprint,
        canEditPathway,
        isCalculating,
        isIchraPathway,
        isProjectedReimbursementModalVisible,
        isProjectedWageUpModalVisible,
        isRhOrAdvisor,
        pathwayUserProfileInfo,
    } = useSelector((state: AppStore) => ({
        activePathwayBlueprintId: state.pathwayUserProfileInfo?.pathwayBlueprintId,
        canCalculatePathwayBlueprint: hasSomePermissions(state, CalculatePathwayBlueprint),
        canEditPathway: hasSomePermissions(state, EditPathway),
        canViewPathwayBlueprints: hasSomePermissions(state, ViewPathwayBlueprints),
        isCalculating: state.pathwayUserProfileInfo?.blueprintIsCalculating,
        isIchraPathway: isIchraPathwayType(state.pathwayUserProfileInfo?.pathwayType),
        isProjectedReimbursementModalVisible:
            state.pathwayCustomProjectedModalState.isCustomReimbursementModalVisible,
        isProjectedWageUpModalVisible:
            state.pathwayCustomProjectedModalState.isCustomWageUpModalVisible,
        isRhOrAdvisor: isRhSelector(state) || isAdvisorSelector(state),
        pathwayUserProfileInfo: state.pathwayUserProfileInfo,
    }));

    const { teamStateId } = useTeamProps();

    const {
        customProjectedReimbursement,
        customProjectedWageUp,
        projectedReimbursement,
        projectedWageUp,
    } = useContributionProps();
    let contributionTypeText = 'Wage+';
    let hasProjectedContribution = hasValue(projectedWageUp);
    let hasCustomProjectedContribution = hasValue(customProjectedWageUp);
    let footerText = 'The gross or before-tax amount given to the employee';
    const allowsCustomReimbursements = isCustomReimbursementsAllowed(
        pathwayUserProfileInfo.pathwayType
    );
    if (isIchraPathway) {
        contributionTypeText = 'Reimbursement';
        hasProjectedContribution = hasValue(projectedReimbursement);
        hasCustomProjectedContribution = hasValue(customProjectedReimbursement);
        footerText = 'The maximum amount of reimbursement the employee can receive for premiums';
    }
    const textToCopy =
        allowsCustomReimbursements && !hasCustomProjectedContribution
            ? formatCurrency(projectedReimbursement, { preserveDecimal: true })
            : null;

    const recalculateOnClick = useCallback(async () => {
        await dispatch(calculatePathwayUser(pathwayUserProfileInfo?.id));
        await dispatch(
            getPathwayUserProfileInfo(pathwayUserProfileInfo?.userId, pathwayUserProfileInfo?.year)
        );
    }, [
        dispatch,
        pathwayUserProfileInfo?.id,
        pathwayUserProfileInfo?.userId,
        pathwayUserProfileInfo?.year,
    ]);
    const setActiveContribution = useCallback(async () => {
        await dispatch(
            applyCalculationForPathwayBlueprint(activePathwayBlueprintId, [
                pathwayUserProfileInfo?.id,
            ])
        );
        await dispatch(
            getPathwayUserProfileInfo(pathwayUserProfileInfo?.userId, pathwayUserProfileInfo?.year)
        );
    }, [activePathwayBlueprintId, dispatch, pathwayUserProfileInfo]);
    const openProjectedContributionModal = useCallback(() => {
        if (isIchraPathway) {
            dispatch(openCustomReimbursementModal(pathwayUserProfileInfo));
        } else {
            dispatch(openCustomWageUpModal(pathwayUserProfileInfo));
        }
    }, [dispatch, isIchraPathway, pathwayUserProfileInfo]);
    const reloadPathwayUserProfileInfo = async () => {
        await dispatch(
            getPathwayUserProfileInfo(pathwayUserProfileInfo?.userId, pathwayUserProfileInfo?.year)
        );
    };

    const showSetActiveContribution =
        showActiveContributionByTeamState(teamStateId) &&
        canCalculatePathwayBlueprint &&
        hasProjectedContribution;
    const showRecalculateAction =
        hasProjectedContribution && canEditPathway && allowsCustomReimbursements;

    const items = useMemo(
        () =>
            [
                {
                    isVisible: showRecalculateAction,
                    onClick: openProjectedContributionModal,
                    text: 'Edit',
                },
                {
                    isConfirm: true,
                    isVisible: canCalculatePathwayBlueprint && hasProjectedContribution,
                    onClick: recalculateOnClick,
                    text: 'Recalculate',
                },
                {
                    isConfirm: true,
                    isVisible: showSetActiveContribution,
                    onClick: setActiveContribution,
                    text: `Set As Active ${contributionTypeText}`,
                },
            ] as IActionButtonItems,
        [
            canCalculatePathwayBlueprint,
            contributionTypeText,
            hasProjectedContribution,
            openProjectedContributionModal,
            recalculateOnClick,
            setActiveContribution,
            showRecalculateAction,
            showSetActiveContribution,
        ]
    );
    if (hasProjectedContribution && isRhOrAdvisor) {
        const canUseAnyProjectedContributionActions = items.some((i) => i.isVisible);
        let actionButtonsVariant: ButtonProps['variant'] = 'outline-secondary';
        let contentClassName = '';
        if (hasCustomProjectedContribution) {
            actionButtonsVariant = 'outline-primary';
            contentClassName = 'text-primary';
        }
        return (
            <React.Fragment>
                {isProjectedReimbursementModalVisible && (
                    <CustomProjectedReimbursementModal afterSave={reloadPathwayUserProfileInfo} />
                )}
                {isProjectedWageUpModalVisible && (
                    <CustomProjectedWageUpModal afterSave={reloadPathwayUserProfileInfo} />
                )}
                <ProfileAttribute
                    data-cy="profile-projected-contribution"
                    footer={<span className="text-muted">{footerText}</span>}
                    label={<PerMonthLabel>{`Projected ${contributionTypeText}`}</PerMonthLabel>}
                    textToCopy={textToCopy}
                >
                    {canUseAnyProjectedContributionActions ? (
                        <ActionButtons
                            disabled={isCalculating}
                            isAlwaysDropdown
                            items={items}
                            size="sm"
                            title={<ProjectedContributionContent />}
                            variant={actionButtonsVariant}
                        />
                    ) : (
                        <ProjectedContributionContent className={contentClassName} />
                    )}
                </ProfileAttribute>
            </React.Fragment>
        );
    } else {
        return <React.Fragment />;
    }
};

export default hot(module)(ProjectedContributionProfileAttribute);
