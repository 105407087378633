import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { IMarketplacePlanDto, IMediSharePlan } from 'api/generated/models';
import MarketplacePlanCard from 'pages/shop/MarketplacePlanCard';
import MediSharePlanCard from 'pages/shop/MediSharePlanCard';
import { IShoppingPlan } from 'pages/shop/shopping';
import React, { ReactNode } from 'react';
import { hot } from 'react-hot-loader';

export const LabelValue = ({ label, children }: { children: ReactNode; label: ReactNode }) => (
    <Stack alignItems="center">
        <Typography
            align="center"
            color={(theme) => theme.palette.muted.main}
            component="div"
            variant="body2"
        >
            {label}
        </Typography>
        <Typography align="center" component="div" fontWeight="bold" variant="body2">
            {children}
        </Typography>
    </Stack>
);

export const MEDISHARE_PLAN_NAME = 'Medi-Share Program';
export type IPlan = {
    isMarketplace?: boolean;
    isMediShare?: boolean;
} & (IMarketplacePlanDto | IMediSharePlan);

const PlanCard = (props: {
    dataCyPrefix?: string;
    handleSelectPlan?: (plan: Partial<IShoppingPlan>) => Promise<void>;
    hideCost?: boolean;
    hideSelectPlan?: boolean;
    householdMembersCovered?: string[];
    individualOnly?: boolean;
    isChunkPlan?: boolean;
    isDisabled?: boolean;
    isIchraFlowShopping?: boolean;
    isPrimaryCovered?: boolean;
    isSelectingPlan?: boolean;
    pathwayPlanCardHeader?: JSX.Element;
    plan: Partial<IShoppingPlan>;
    recommendedPlanDescription?: string;
    recommendedPlanLabel?: string;
    showRecommendedPlanBookmark?: boolean;
    showTaxSavings?: boolean;
}) => {
    if (props.plan.isMediShare) {
        return <MediSharePlanCard {...props} />;
    } else if (props.plan.isMarketplace) {
        return <MarketplacePlanCard {...props} />;
    }
    return <React.Fragment />;
};

export default hot(module)(PlanCard);
