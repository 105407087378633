import { AvTimer, CancelOutlined, CheckCircleOutline, InfoOutlined } from '@mui/icons-material';
import { Card, CardContent, Tooltip } from '@mui/material';
import { clearTeamFinch } from 'actions/clear';
import { getFinchProviders } from 'actions/finch/getFinchProviders';
import { getFinchStatus } from 'actions/finch/getFinchStatus';
import {
    DeductionStatus,
    FinchEmploymentSubtypes,
    FinchIntegrationStatuses,
} from 'api/generated/enums';
import { ITeam } from 'api/generated/models';
import { EditPayrollIntegrationConfiguration } from 'api/generated/permissions';
import { IValueType } from 'components/EditableAttribute';
import EditableNumberAttribute from 'components/EditableNumberAttribute';
import EditableSelectAttribute from 'components/EditableSelectAttribute';
import { ISaveEditableTextField } from 'components/EditableTextField';
import ProfileAttribute from 'components/ProfileAttribute';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import startCase from 'lodash/startCase';
import DashboardCardHeader from 'pages/dashboard/DashboardCardHeader';
import FinchActionButtons from 'pages/integrations/FinchActionButtons';
import { IntegrationsLoadingContext } from 'pages/integrations/IntegrationsPage';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors/index';
import { formatDateAtTime } from 'utilities/format';
import { onChange } from 'utilities/forms';
import { enumToNameValueArray, flagsToValuesArray } from 'utilities/index';
import { array, number, string } from 'yup';

export const finchEmploymentSubtypeIdNames = {
    [FinchEmploymentSubtypes.FullTime]: 'Full-time',
    [FinchEmploymentSubtypes.PartTime]: 'Part-time',
    [FinchEmploymentSubtypes.Intern]: 'Intern',
    [FinchEmploymentSubtypes.Temp]: 'Temporary',
    [FinchEmploymentSubtypes.Seasonal]: 'Seasonal',
};

export const FINCH_EMPLOYMENT_SUBTYPE_ITEMS = enumToNameValueArray(FinchEmploymentSubtypes, {
    formatName: startCase,
    nameKey: 'text',
    nameMap: {
        FullTime: finchEmploymentSubtypeIdNames[FinchEmploymentSubtypes.FullTime],
        Intern: finchEmploymentSubtypeIdNames[FinchEmploymentSubtypes.Intern],
        PartTime: finchEmploymentSubtypeIdNames[FinchEmploymentSubtypes.PartTime],
        Seasonal: finchEmploymentSubtypeIdNames[FinchEmploymentSubtypes.Seasonal],
        Temp: finchEmploymentSubtypeIdNames[FinchEmploymentSubtypes.Temp],
    },
});

const DEDUCTION_DEADLINE_MIN_VALUE = 1;
const DEDUCTION_DEADLINE_MAX_VALUE = 15;

const FinchIntegrationSection = ({ save }: { save: ISaveEditableTextField<ITeam> }) => {
    const dispatch = useThunkDispatch();
    const { team } = useTeamProps();
    const { canEditHrsIntegrationConfiguration, finchProviders, teamFinchStatus } = useSelector(
        (state: AppStore) => ({
            canEditHrsIntegrationConfiguration: hasSomePermissions(
                state,
                EditPayrollIntegrationConfiguration
            ),
            finchProviders: state.finch.providers,
            teamFinchStatus: state.teamFinch.status,
        })
    );

    const { loading, setLoading } = useContext(IntegrationsLoadingContext);
    const [finchProviderId, setFinchProviderId] = useState<string | undefined>(
        team?.finchProviderId
    );
    const [supportedFinchEmploymentSubtypes, setSupportedFinchEmploymentSubtypes] = useState<
        FinchEmploymentSubtypes[]
    >(
        flagsToValuesArray(
            team?.supportedFinchEmploymentSubtypes,
            finchEmploymentSubtypeIdNames,
            'value'
        ).map((x) => x['value'] as FinchEmploymentSubtypes)
    );

    const [deductionDeadlineDay, setDeductionDeadlineDay] = useState<number | undefined>(
        team?.deductionDeadlineDay
    );

    const saveFinchProviderId = useCallback(
        async (name, value) => {
            await save(name, value);
        },
        [save]
    );

    const saveSupportedFinchEmploymentSubtypes: ISaveEditableTextField<ITeam> = async (
        name,
        valueAsArray
    ) => {
        const value = (valueAsArray as string[]).reduce(
            (previousValue, currentValue) => Number(currentValue) | previousValue,
            0
        );
        await save(name, value);
    };

    const getDeductionStatusTooltip = (status: DeductionStatus | undefined): string | undefined =>
        status === undefined ||
        status === DeductionStatus.NotConfigured ||
        status === DeductionStatus.Error
            ? 'To register deductions click the "Register Deductions" button in the Actions dropdown of the Finch Configuration section.'
            : status === DeductionStatus.Processing
            ? 'Please note that with some providers, processing could take up to 7 business days.'
            : undefined;

    useEffect(() => {
        if (finchProviders === null || (finchProviders && finchProviders.length === 0)) {
            dispatch(getFinchProviders());
        }
        if (
            !teamFinchStatus &&
            team?.finchIntegrationStatus !== FinchIntegrationStatuses.NotConfigured
        ) {
            dispatch(getFinchStatus(team?.teamId ?? ''));
        }
        return () => {
            clearTeamFinch();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        const teamFinchStatusLoadedIfConnected =
            team &&
            (teamFinchStatus || team.finchIntegrationStatus !== FinchIntegrationStatuses.Connected);
        if (teamFinchStatusLoadedIfConnected && finchProviders && loading) {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finchProviders, team, teamFinchStatus]);

    const OverrideActionButtons = useCallback(() => <FinchActionButtons team={team} />, [team]);

    return (
        <React.Fragment>
            <Card>
                <CardContent>
                    <DashboardCardHeader
                        header="Finch Configuration"
                        OverrideActionButtons={OverrideActionButtons}
                    />
                    <hr />
                    <ProfileAttribute label="Status">
                        {startCase(
                            FinchIntegrationStatuses[
                                team?.finchIntegrationStatus ??
                                    FinchIntegrationStatuses.NotConfigured
                            ]
                        )}
                    </ProfileAttribute>
                    {finchProviders && (
                        <EditableSelectAttribute
                            formatter={(x: IValueType) =>
                                finchProviders.find((p) => p.id === x)?.displayName ?? ''
                            }
                            items={finchProviders}
                            label="Provider"
                            name="finchProviderId"
                            onChange={onChange(setFinchProviderId)}
                            optionText="displayName"
                            optionValue="id"
                            save={saveFinchProviderId}
                            validationSchema={string().label('Provider')}
                            value={finchProviderId}
                        />
                    )}
                    <EditableSelectAttribute
                        formatter={() =>
                            supportedFinchEmploymentSubtypes
                                .map(
                                    (x) =>
                                        finchEmploymentSubtypeIdNames[
                                            Number(x) as FinchEmploymentSubtypes
                                        ]
                                )
                                .joinSerialComma()
                        }
                        infoTooltip="Employees that do not have one of the selected employment types will be added to the platform, but marked inactive."
                        items={FINCH_EMPLOYMENT_SUBTYPE_ITEMS}
                        label="Active Employee Filter Criteria"
                        name="supportedFinchEmploymentSubtypes"
                        onChange={onChange(setSupportedFinchEmploymentSubtypes)}
                        optionText="text"
                        optionValue="value"
                        save={saveSupportedFinchEmploymentSubtypes}
                        SelectProps={{
                            multiple: true,
                            native: false,
                        }}
                        validationSchema={array(number()).label('Employment Subtype')}
                        value={(supportedFinchEmploymentSubtypes as unknown) as string[]}
                    />
                    {canEditHrsIntegrationConfiguration && (
                        <EditableNumberAttribute
                            format="##"
                            infoTooltip="Select a day (1–15) to set the deadline for setting deductions before payroll closes. Finch requires up to 7 days to process, so the deadline should be set at least 8 days before this team's payroll closing date. For example, if payroll closes on the 15th, select a deadline of 7 or earlier to ensure timely processing."
                            isOptional
                            label="Deduction Deadline Day"
                            name="deductionDeadlineDay"
                            onChange={onChange(setDeductionDeadlineDay)}
                            placeholder="Enter a day 1 - 15"
                            save={save}
                            validationSchema={number()
                                .min(DEDUCTION_DEADLINE_MIN_VALUE, 'Value must be between 1 and 15')
                                .max(DEDUCTION_DEADLINE_MAX_VALUE, 'Value must be between 1 and 15')
                                .typeError('A valid number is required')}
                            value={deductionDeadlineDay}
                        />
                    )}
                </CardContent>
            </Card>
            {teamFinchStatus && (
                <Card>
                    <CardContent>
                        <DashboardCardHeader header="Finch Status" />
                        <hr />
                        <ProfileAttribute
                            infoTooltip={getDeductionStatusTooltip(
                                team?.finchPostTaxDeductionStatus
                            )}
                            label="Post-tax Deduction Status"
                        >
                            {startCase(
                                DeductionStatus[
                                    team?.finchPostTaxDeductionStatus ??
                                        DeductionStatus.NotConfigured
                                ]
                            )}
                        </ProfileAttribute>
                        <ProfileAttribute
                            infoTooltip={getDeductionStatusTooltip(
                                team?.finchPreTaxDeductionStatus
                            )}
                            label="Pre-tax Deduction Status"
                        >
                            {startCase(
                                DeductionStatus[
                                    team?.finchPreTaxDeductionStatus ??
                                        DeductionStatus.NotConfigured
                                ]
                            )}
                        </ProfileAttribute>
                        <ProfileAttribute label="Products">
                            {teamFinchStatus.introspect?.products?.map((product, index) => (
                                <div key={`${product}-${index}`}>{startCase(product)}</div>
                            ))}
                        </ProfileAttribute>
                        <ProfileAttribute label="Authentication Methods">
                            {teamFinchStatus.introspect?.authenticationMethods?.map(
                                (authenticationMethod, index) => {
                                    const status = authenticationMethod.connectionStatus?.status;

                                    let IconComponent = <React.Fragment />;

                                    if (status === 'connected') {
                                        IconComponent = <CheckCircleOutline color="success" />;
                                    } else if (status === 'pending' || status === 'processing') {
                                        IconComponent = <AvTimer color="secondary" />;
                                    } else if (
                                        status === 'error_no_account_setup' ||
                                        status === 'error_permissions'
                                    ) {
                                        IconComponent = <CancelOutlined color="warning" />;
                                    } else if (status === 'reauth') {
                                        IconComponent = <InfoOutlined color="error" />;
                                    }

                                    return (
                                        <React.Fragment
                                            key={`${authenticationMethod.type}-${index}`}
                                        >
                                            <div>
                                                {startCase(authenticationMethod.type)}:{' '}
                                                {startCase(
                                                    authenticationMethod.connectionStatus?.status
                                                )}
                                                {authenticationMethod.connectionStatus?.message && (
                                                    <Tooltip
                                                        className="ml-1"
                                                        title={
                                                            authenticationMethod.connectionStatus
                                                                .message
                                                        }
                                                    >
                                                        {IconComponent}
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </React.Fragment>
                                    );
                                }
                            )}
                        </ProfileAttribute>
                        <ProfileAttribute label="Recent Automated Jobs">
                            {teamFinchStatus.jobs?.map((job, index) =>
                                teamFinchStatus.jobs ? (
                                    <React.Fragment key={job.jobId}>
                                        <div>
                                            <div>
                                                <strong>Type:</strong> {startCase(job.type)}
                                            </div>
                                            <div>
                                                <strong>Status:</strong> {startCase(job.status)}
                                            </div>
                                            {job.scheduledAt && (
                                                <div>
                                                    <strong>Scheduled At:</strong>{' '}
                                                    {formatDateAtTime(job.scheduledAt)}
                                                </div>
                                            )}
                                            {job.startedAt && (
                                                <div>
                                                    <strong>Started At:</strong>{' '}
                                                    {formatDateAtTime(job.startedAt)}
                                                </div>
                                            )}
                                            {job.completedAt && (
                                                <div>
                                                    <strong>Completed At:</strong>{' '}
                                                    {formatDateAtTime(job.completedAt)}
                                                </div>
                                            )}
                                        </div>
                                        {index < teamFinchStatus.jobs.length - 1 && <hr />}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment key={job.jobId} />
                                )
                            )}
                        </ProfileAttribute>
                    </CardContent>
                </Card>
            )}
        </React.Fragment>
    );
};

export default hot(module)(FinchIntegrationSection);
