import ConditionalTooltip from 'components/ConditionalTooltip';
import IconTooltip from 'components/IconTooltip';
import useContributionProps from 'hooks/useContributionProps';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities';
import { formatCurrency, formatDateForDisplay } from 'utilities/format';
import { isIchraPathwayType } from 'utilities/pathways';

const ProjectedContributionContent = ({ className }: { className?: string }) => {
    const { isIchraPathway, pathwayUserProfileInfo } = useSelector((state: AppStore) => ({
        isIchraPathway: isIchraPathwayType(state.pathwayUserProfileInfo?.pathwayType),
        pathwayUserProfileInfo: state.pathwayUserProfileInfo,
    }));

    const {
        customProjectedReimbursement,
        customProjectedWageUp,
        isStale,
        projectedReimbursement,
        projectedWageUp,
    } = useContributionProps();
    let hasCustomProjectedContribution = hasValue(customProjectedWageUp);
    let projectedContribution = formatCurrency(projectedWageUp);
    let titleText = pathwayUserProfileInfo.customProjectedWageUpNote;
    if (isIchraPathway) {
        hasCustomProjectedContribution = hasValue(customProjectedReimbursement);
        projectedContribution = formatCurrency(projectedReimbursement, { preserveDecimal: true });
        titleText = pathwayUserProfileInfo.customProjectedReimbursementNote;
    }
    const ContentContainer = hasCustomProjectedContribution ? 'u' : 'span';
    return (
        <React.Fragment>
            {isStale && (
                <IconTooltip
                    className="mr-1"
                    icon="sync"
                    iconSize="1"
                    prefix="mdi"
                    title={`There have been changes to member data that will likely change the costs for this pathway blueprint

    Last Calculation Date - ${formatDateForDisplay(pathwayUserProfileInfo.lastCalculatedDate)}`}
                    variant="warning"
                ></IconTooltip>
            )}
            <ConditionalTooltip
                isDisabled={!hasCustomProjectedContribution}
                title={titleText as string}
            >
                <ContentContainer className={className}>{projectedContribution}</ContentContainer>
            </ConditionalTooltip>
        </React.Fragment>
    );
};

export default hot(module)(ProjectedContributionContent);
