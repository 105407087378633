import { PathwayTypes } from 'api/generated/enums';
import { EditPathway, ViewPathwayBlueprints } from 'api/generated/permissions';
import ConditionalTooltip from 'components/ConditionalTooltip';
import DoubleBangIcon from 'components/DoubleBangIcon';
import ProfileAttribute from 'components/ProfileAttribute';
import Tooltip from 'components/Tooltip';
import useContributionProps from 'hooks/useContributionProps';
import { PerMonthLabel } from 'pages/profile/UserBudgetProfileAttribute';
import React, { ReactNode, useMemo } from 'react';
import Row from 'react-bootstrap/Row';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { isAdvisorSelector, isRhSelector } from 'selectors/role';
import { hasValue } from 'utilities';
import { formatCurrency } from 'utilities/format';
import { getActiveAndProjectedDifferentMessage, isIchraPathwayType } from 'utilities/pathways';

const ActiveContributionProfileAttribute = () => {
    const {
        canEditPathway,
        isIchraPathway,
        isRhOrAdvisor,
        pathwayUserProfileInfo,
        showBudget,
    } = useSelector((state: AppStore) => ({
        activePathwayBlueprintId: state.pathwayUserProfileInfo?.pathwayBlueprintId,
        canEditPathway: hasSomePermissions(state, EditPathway),
        canViewPathwayBlueprints: hasSomePermissions(state, ViewPathwayBlueprints),
        isIchraPathway: isIchraPathwayType(state.pathwayUserProfileInfo?.pathwayType),
        isRhOrAdvisor: isRhSelector(state) || isAdvisorSelector(state),
        pathwayUserProfileInfo: state.pathwayUserProfileInfo,
        showBudget: ([PathwayTypes.Flat, PathwayTypes.Custom] as (
            | PathwayTypes
            | undefined
        )[]).includes(state.pathwayUserProfileInfo.pathwayType),
        year: state.profileState.selectedYear,
    }));
    const {
        activeContribution,
        shouldDisplayActiveContributionProfileAttribute,
    } = useContributionProps();
    const canViewCustom = activeContribution?.isCustom && isRhOrAdvisor;
    const activeWageUpAmountForYear = activeContribution?.wageUp;
    const activeReimbursementAmountForYear = activeContribution?.reimbursement;
    const netWageUp = activeContribution?.netWageUp;
    const {
        activeContributionValue,
        footerText,
        labelText,
        noActiveContributionForYear,
        note,
        projectedContributionMismatch,
        title,
    } = useMemo(() => {
        let _activeContributionValue = formatCurrency(activeWageUpAmountForYear);
        let _footerText = 'The gross or before-tax amount given to the employee';
        let _labelText = 'Wage+';
        let _noActiveContributionForYear = !hasValue(activeWageUpAmountForYear);
        let _note = activeContribution?.note;
        if (isIchraPathway) {
            _activeContributionValue = formatCurrency(activeReimbursementAmountForYear, {
                preserveDecimal: true,
            });
            _footerText =
                'The maximum amount of reimbursement the employee can receive for premiums';
            _labelText = 'Reimbursement';
            _noActiveContributionForYear = !hasValue(activeReimbursementAmountForYear);
            _note = activeContribution?.reimbursementNote;
        }
        const _title = getActiveAndProjectedDifferentMessage(_labelText);
        return {
            activeContributionValue: _activeContributionValue,
            footerText: _footerText,
            labelText: _labelText,
            noActiveContributionForYear: _noActiveContributionForYear,
            note: _note,
            projectedContributionMismatch: pathwayUserProfileInfo.hasMismatchedContributions,
            title: _title,
        };
    }, [
        activeContribution?.note,
        activeContribution?.reimbursementNote,
        activeReimbursementAmountForYear,
        activeWageUpAmountForYear,
        isIchraPathway,
        pathwayUserProfileInfo.hasMismatchedContributions,
    ]);

    const ContentContainer = canViewCustom
        ? ({ children }: { children: ReactNode }) => <u className="text-primary">{children}</u>
        : 'span';
    let activeContributionContent = (
        <ConditionalTooltip isDisabled={!canViewCustom} title={note as string}>
            <ContentContainer>{activeContributionValue}</ContentContainer>
        </ConditionalTooltip>
    );
    if (canEditPathway && projectedContributionMismatch) {
        activeContributionContent = (
            <Row className="text-danger align-items-center justify-content-end" noGutters>
                <Tooltip title={title}>
                    <DoubleBangIcon className="mr-1" />
                </Tooltip>
                {activeContributionContent}
            </Row>
        );
    }
    if (noActiveContributionForYear || !shouldDisplayActiveContributionProfileAttribute) {
        return <React.Fragment />;
    }
    return (
        <React.Fragment>
            <ProfileAttribute
                data-cy="profile-contribution"
                footer={<span className="text-muted">{footerText}</span>}
                label={<PerMonthLabel>{`Active ${labelText}`}</PerMonthLabel>}
                textToCopy={activeContributionValue}
            >
                {activeContributionContent}
            </ProfileAttribute>
            {showBudget && (
                <ProfileAttribute label={<PerMonthLabel>Budget</PerMonthLabel>}>
                    {formatCurrency(netWageUp)}
                </ProfileAttribute>
            )}
        </React.Fragment>
    );
};

export default hot(module)(ActiveContributionProfileAttribute);
