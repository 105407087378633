import { Stack, Switch, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { getMonthAndYearOfMostRecentPayrollReport } from 'actions/payroll/getMonthAndYearOfMostRecentPayrollReport';
import {
    GET_PAYROLL_REPORTS_FOR_YEAR_ACTION,
    getPayrollReportsForYear,
} from 'actions/payroll/getPayrollReportsForYear';
import { REFRESH_PAYROLL_REPORT_ACTION } from 'actions/payroll/refreshPayrollReport';
import { getTeamOperationsInfo } from 'actions/team/getTeamOperationsInfo';
import { GroupLevels, LineFourteenCodes, LineSixteenCodes, UserStatus } from 'api/generated/enums';
import {
    IPayrollReportDto,
    IPayrollReportUserChangeLog,
    IPayrollReportUserSnapshotDto,
    IPostTaxWithholdingDto,
    IPreTaxWithholdingsDto,
    IReimbursablePremiumDto,
    IReimbursementSummary,
    ITaxFreeReimbursementsDto,
    PayrollReportUserSnapshotMajorMedicalPlan,
} from 'api/generated/models';
import {
    EditPayrollReports,
    ViewPayrollReports,
    ViewTeamSharedPayrollReports,
} from 'api/generated/permissions';
import {
    COVERAGE_LEVEL_COLUMN_ID,
    COVERAGE_LEVEL_FILTER_ID,
    COVERAGE_LEVEL_OPTIONS,
    COVERAGE_LEVEL_PARAM,
} from 'components/commonTableComponents/CoverageLevelHeader';
import {
    MEMBER_STATUS_COLUMN_ID,
    MEMBER_STATUS_FILTER_ID,
    STATUS_PARAM,
    USER_STATUS_OPTIONS,
} from 'components/commonTableComponents/MemberStatusHeader';
import NoRowsMessage from 'components/reactTable/NoRowsMessage';
import { StyledReactTable } from 'components/reactTable/StyledReactTable';
import Skeleton from 'components/Skeleton';
import TextField from 'components/TextField';
import { MONTH_MAX, MONTHS } from 'constants/date';
import useModalState from 'hooks/useModalState';
import useQuery from 'hooks/useQuery';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import inRange from 'lodash/inRange';
import isUndefined from 'lodash/isUndefined';
import startCase from 'lodash/startCase';
import sumBy from 'lodash/sumBy';
import GeneratePayrollReportModal from 'pages/payrollReports/GeneratePayrollReportModal';
import { setPayrollReportSelectedYear } from 'pages/payrollReports/payrollReportActions';
import PayrollReportsPageHeader from 'pages/payrollReports/PayrollReportsPageHeader';
import {
    getFsaColumn,
    getHsaColumn,
    getIchraReimbursementColumn,
    getMemberColumn,
    getPostTaxWithholdingColumn,
    getPreTaxWithholdingColumn,
    getReimbursablePremiumsColumn,
    getTaxCodesColumn,
    getTaxFreeReimbursementsColumn,
    getWageUpColumn,
    MAJOR_MEDICAL_BENEFIT_TYPE_COLUMN_ID,
    MAJOR_MEDICAL_BENEFIT_TYPE_FILTER_ID,
    MAJOR_MEDICAL_BENEFIT_TYPE_OPTIONS,
    MAJOR_MEDICAL_BENEFIT_TYPE_PARAM,
    NAME_COLUMN_ID,
} from 'pages/payrollReports/PayrollTableColumns';
import { useHrsForPayrollReport } from 'pages/payrollReports/useHrsForPayrollReport';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Column, Filters, FilterTypes, Row, SortingRule, TableRowProps } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity } from 'selectors/activity';
import { isRhSelector, isTeamAdminSelector, isTeamPayrollAdminSelector } from 'selectors/role';
import { hasContents, hasValue } from 'utilities';
import { onChange } from 'utilities/forms';
import { getPlanTypeName } from 'utilities/plans';
import { getColumnWidth } from 'utilities/reactTable';

const GLOBAL_FILTER_ID = 'globalFilter';
const DEFAULT_PAGE_SIZE = 50;
const initialSortBy: SortingRule<IPayrollReportRow>[] = [{ desc: false, id: NAME_COLUMN_ID }];
const filterByParentRow = <T extends { isParentRow: boolean }>(
    rows: Row<T>[],
    filter: (row: Row<T>) => boolean
) => {
    const hasSubRows = rows.some((x) => !x.original.isParentRow);
    if (hasSubRows) {
        return rows;
    }
    const rowsToShow: Row<T>[] = [];
    rows.forEach((row) => {
        const shouldIncludeRow = filter(row);
        if (row.original.isParentRow && shouldIncludeRow) {
            rowsToShow.push(row);
        }
    });

    return rowsToShow;
};
const filterTypes: FilterTypes<IPayrollReportRow> = {
    [COVERAGE_LEVEL_FILTER_ID]: (rows, _, filterValue: string[]) =>
        filterByParentRow(
            rows,
            ({ original: { coverageLevel } }) =>
                (coverageLevel === undefined && filterValue.includes('None')) ||
                filterValue.includes(startCase(GroupLevels[coverageLevel as GroupLevels]))
        ),
    [GLOBAL_FILTER_ID]: (rows, ids, filterValue: string) => {
        if (!hasValue(filterValue)) {
            return rows;
        }
        const searchParts = filterValue
            .split(',')
            .filter((x) => hasValue(x))
            .map((x) => x.toLowerCase().trim());

        return filterByParentRow(rows, (row) =>
            ids.some((id) => {
                const value = String(row.values[id]).toLowerCase();
                return searchParts.some((s) => value.includes(s));
            })
        );
    },
    [MAJOR_MEDICAL_BENEFIT_TYPE_FILTER_ID]: (rows, _, filterValue: string[]) =>
        filterByParentRow(
            rows,
            ({ original: { majorMedicalPlans } }) =>
                ((!hasValue(majorMedicalPlans) || majorMedicalPlans.length === 0) &&
                    filterValue.includes('None')) ||
                (majorMedicalPlans?.some((x) =>
                    filterValue.includes(getPlanTypeName(x.planType))
                ) ??
                    false)
        ),
    [MEMBER_STATUS_FILTER_ID]: (rows, _, filterValue: string[]) =>
        filterByParentRow(
            rows,
            ({ original: { memberStatus: status } }) =>
                (status === undefined && filterValue.includes('None')) ||
                filterValue.includes(startCase(UserStatus[status as UserStatus]))
        ),
};

let background = 'white';
const getCustomRowProps = (row: Row<IPayrollReportRow>): TableRowProps => {
    if (row.original.isParentRow) {
        background = background === 'white' ? '#edf0f5' : 'white';
    }
    return {
        key: row.id,
        style: {
            background,
        },
    };
};

export type IPayrollReportRow = {
    changeLog?: IPayrollReportUserChangeLog;
    coverageLevel?: GroupLevels;
    customFsaEmployerContribution?: number;
    customHsaEmployerContribution?: number;
    expanded: boolean;
    firstName?: string;
    fsaEmployerContribution?: number;
    hsaEmployerContribution?: number;
    ichraReimbursement?: number;
    ichraReimbursementAdjustment?: number;
    ichraReimbursementTotal?: number;
    isHsaEligible?: boolean;
    isLocked?: boolean;
    isParentRow: boolean;
    lastName?: string;
    lineFourteenCode?: LineFourteenCodes;
    lineSeventeenCode?: string;
    lineSixteenCode?: LineSixteenCodes;
    majorMedicalPlans?: PayrollReportUserSnapshotMajorMedicalPlan[];
    memberStatus?: UserStatus;
    month?: number;
    note?: string;
    payrollReportUserSnapshot?: IPayrollReportUserSnapshotDto;
    payrollSystemId?: string;
    postTaxAdjustment?: number;
    postTaxAmount?: number;
    postTaxAmountTotal?: number;
    postTaxBenefitType?: string;
    postTaxTotal?: number;
    postTaxWithholdings: IPostTaxWithholdingDto[] | undefined;
    preTaxAdjustment?: number;
    preTaxAmount?: number;
    preTaxAmountTotal?: number;
    preTaxBenefitType?: string;
    preTaxBenefits: IPreTaxWithholdingsDto[] | undefined;
    preTaxTotal?: number;
    reimbursablePremiumAdjustment?: number;
    reimbursablePremiumAmount?: number;
    reimbursablePremiumAmountTotal?: number;
    reimbursablePremiumBenefitType?: string;
    reimbursablePremiumId?: string;
    reimbursablePremiumRowTotal?: number;
    reimbursablePremiumTotal?: number;
    reimbursablePremiums?: IReimbursablePremiumDto[];
    reimbursementSummary?: IReimbursementSummary;
    setWithholdingAndWageUp?: boolean;
    taxFreeReimbursementAdjustment?: number;
    taxFreeReimbursementAmount?: number;
    taxFreeReimbursementAmountTotal?: number;
    taxFreeReimbursementTotal?: number;
    taxFreeReimbursementType?: string;
    taxFreeReimbursements?: ITaxFreeReimbursementsDto[];
    userId?: string;
    wageUp?: number;
    wageUpAdjustment?: number;
    wageUpTotal?: number;
    workLocation?: string;
    year?: number;
};

const createParentRow = (
    userSnapshot: IPayrollReportUserSnapshotDto,
    payrollReport: IPayrollReportDto | undefined
): IPayrollReportRow => {
    const firstPostTaxWithholding = userSnapshot.postTaxWithholdings?.[0];
    const firstTaxFreeReimbursement = userSnapshot.taxFreeReimbursements?.[0];
    const firstPreTaxBenefit = userSnapshot.preTaxWithholdings?.[0];
    const firstReimbursablePremium = userSnapshot.reimbursablePremiums?.[0];
    return {
        changeLog: payrollReport?.changes?.find(
            (x) => x.payrollReportUserSnapshotId === userSnapshot.id
        ),
        coverageLevel: userSnapshot.groupLevel,
        customFsaEmployerContribution: userSnapshot.customFsaEmployerContribution,
        customHsaEmployerContribution: userSnapshot.customHsaEmployerContribution,
        expanded: true,
        firstName: userSnapshot.firstName,
        fsaEmployerContribution: userSnapshot.fsaEmployerContribution,
        hsaEmployerContribution: userSnapshot.hsaEmployerContribution,
        ichraReimbursement: userSnapshot.ichraReimbursement,
        ichraReimbursementAdjustment: userSnapshot.ichraReimbursementAdjustment,
        ichraReimbursementTotal: userSnapshot.ichraReimbursementTotal,
        isHsaEligible: userSnapshot.isHsaEligible,
        isParentRow: true,
        lastName: userSnapshot.lastName,
        lineFourteenCode: userSnapshot.lineFourteenCode,
        lineSeventeenCode: userSnapshot.lineSeventeenCode,
        lineSixteenCode: userSnapshot.lineSixteenCode,
        majorMedicalPlans: userSnapshot.majorMedicalPlans,
        memberStatus: userSnapshot.userStatus,
        month: payrollReport?.month,
        note: userSnapshot.note,
        payrollReportUserSnapshot: userSnapshot,
        payrollSystemId: userSnapshot.payrollSystemId,
        postTaxAdjustment: userSnapshot.postTaxWithholdingAdjustment,
        postTaxAmount: firstPostTaxWithholding?.amount,
        postTaxAmountTotal: sumBy(userSnapshot.postTaxWithholdings, (x) => x.amount),
        postTaxBenefitType: firstPostTaxWithholding?.benefitType,
        postTaxTotal: userSnapshot.postTaxWithholdingTotal,
        postTaxWithholdings: userSnapshot.postTaxWithholdings,
        preTaxAdjustment: userSnapshot.preTaxWithholdingAdjustment,
        preTaxAmount: firstPreTaxBenefit?.amount,
        preTaxAmountTotal: sumBy(userSnapshot.preTaxWithholdings, (x) => x.amount),
        preTaxBenefits: userSnapshot.preTaxWithholdings,
        preTaxBenefitType: firstPreTaxBenefit?.benefitType,
        preTaxTotal: userSnapshot.preTaxWithholdingTotal,
        reimbursablePremiumAdjustment: firstReimbursablePremium?.adjustment,
        reimbursablePremiumAmount: firstReimbursablePremium?.amount,
        reimbursablePremiumAmountTotal: sumBy(userSnapshot.reimbursablePremiums, (x) => x.amount),
        reimbursablePremiumBenefitType: firstReimbursablePremium?.benefitType,
        reimbursablePremiumId: firstReimbursablePremium?.id,
        reimbursablePremiumRowTotal: firstReimbursablePremium?.total,
        reimbursablePremiums: userSnapshot.reimbursablePremiums,
        reimbursablePremiumTotal: sumBy(userSnapshot.reimbursablePremiums, (x) => x.total),
        reimbursementSummary: userSnapshot.reimbursementSummary,
        setWithholdingAndWageUp: userSnapshot.setWithholdingAndWageUp,
        taxFreeReimbursementAdjustment: userSnapshot.taxFreeReimbursementAdjustment,
        taxFreeReimbursementAmount: firstTaxFreeReimbursement?.amount,
        taxFreeReimbursementAmountTotal: sumBy(userSnapshot.taxFreeReimbursements, (x) => x.amount),
        taxFreeReimbursements: userSnapshot.taxFreeReimbursements,
        taxFreeReimbursementTotal: userSnapshot.taxFreeReimbursementTotal,
        taxFreeReimbursementType: firstTaxFreeReimbursement?.benefitType,
        userId: userSnapshot.userId,
        wageUp: userSnapshot.wageUp,
        wageUpAdjustment: userSnapshot.wageUpAdjustment,
        wageUpTotal: userSnapshot.wageUpTotal,
        workLocation: userSnapshot.workLocation,
        year: payrollReport?.year,
    };
};

const getSubRows = (payrollReportRow: IPayrollReportRow): IPayrollReportRow[] => {
    const maxLength = Math.max(
        payrollReportRow.postTaxWithholdings?.length ?? 0,
        payrollReportRow.preTaxBenefits?.length ?? 0,
        payrollReportRow.reimbursablePremiums?.length ?? 0,
        payrollReportRow.taxFreeReimbursements?.length ?? 0
    );
    if (maxLength <= 1) {
        return [];
    }

    const subRows: IPayrollReportRow[] = [];
    for (let i = 1; i < maxLength; i++) {
        subRows.push({
            expanded: false,
            isParentRow: false,
            month: payrollReportRow?.month,
            note: payrollReportRow.note,
            payrollReportUserSnapshot: payrollReportRow.payrollReportUserSnapshot,
            postTaxAmount: payrollReportRow.postTaxWithholdings?.[i]?.amount,
            postTaxBenefitType: payrollReportRow.postTaxWithholdings?.[i]?.benefitType,
            postTaxWithholdings: [],
            preTaxAmount: payrollReportRow.preTaxBenefits?.[i]?.amount,
            preTaxBenefits: [],
            preTaxBenefitType: payrollReportRow.preTaxBenefits?.[i]?.benefitType,
            reimbursablePremiumAdjustment: payrollReportRow.reimbursablePremiums?.[i]?.adjustment,
            reimbursablePremiumAmount: payrollReportRow.reimbursablePremiums?.[i]?.amount,
            reimbursablePremiumBenefitType: payrollReportRow.reimbursablePremiums?.[i]?.benefitType,
            reimbursablePremiumId: payrollReportRow.reimbursablePremiums?.[i]?.id,
            reimbursablePremiumRowTotal: payrollReportRow.reimbursablePremiums?.[i]?.total,
            reimbursablePremiums: [],
            setWithholdingAndWageUp: payrollReportRow.setWithholdingAndWageUp,
            taxFreeReimbursementAmount: payrollReportRow.taxFreeReimbursements?.[i]?.amount,
            taxFreeReimbursements: [],
            taxFreeReimbursementType: payrollReportRow.taxFreeReimbursements?.[i]?.benefitType,
            year: payrollReportRow?.year,
        });
    }

    return subRows;
};

const QUERY_PARAM = {
    MONTH: 'month',
    YEAR: 'year',
} as const;

const PayrollReportsPage = () => {
    const dispatch = useThunkDispatch();
    const { activeDate, payrollReportSharingEnabled, teamId, team } = useTeamProps();
    const {
        cancelTokenSource,
        canEditReport,
        canGetPayrollReportsForYear,
        isAle,
        isLoading,
        isRh,
        mostRecentPayrollReportYearAndMonth,
        payrollReports,
        selectedYear,
        shouldInitializeWithMostRecentPayrollReport,
    } = useSelector((state: AppStore) => ({
        cancelTokenSource: state.payrollReportState.cancelTokenSource,
        canEditReport: hasSomePermissions(state, EditPayrollReports),
        canGetPayrollReportsForYear:
            hasSomePermissions(state, ViewPayrollReports) ||
            (payrollReportSharingEnabled &&
                hasSomePermissions(state, ViewTeamSharedPayrollReports)),
        isAle: state.teamOperationsInfo?.isAle ?? false,
        isLoading: hasApiActivity(
            state,
            GET_PAYROLL_REPORTS_FOR_YEAR_ACTION,
            REFRESH_PAYROLL_REPORT_ACTION
        ),
        isRh: isRhSelector(state),
        mostRecentPayrollReportYearAndMonth: state.payrollReportYearMonth,
        payrollReports: state.payrollReports,
        selectedYear: state.payrollReportState.payrollReportSelectedYear,
        shouldInitializeWithMostRecentPayrollReport:
            (isTeamAdminSelector(state) || isTeamPayrollAdminSelector(state)) &&
            payrollReportSharingEnabled,
    }));
    const [search, setSearch] = useState('');
    const [query, setQuery] = useQuery();
    const coverageLevelQueryParam = query.get(COVERAGE_LEVEL_PARAM)?.split(',');
    const majorMedicalBenefitTypeQueryParam = query
        .get(MAJOR_MEDICAL_BENEFIT_TYPE_PARAM)
        ?.split(',');
    const statusQueryParam = query.get(STATUS_PARAM)?.split(',');
    const monthQueryParam = query.get(QUERY_PARAM.MONTH);
    const yearQueryParam = query.get(QUERY_PARAM.YEAR);
    const [month, setMonth] = useState(1);
    const [showTaxCodeColumns, setShowTaxCodeColumns] = useState<boolean>();
    const {
        closeModal: closeGenerateReportModal,
        isVisible: isGenerateReportModalVisible,
        openModal: openGenerateReportModal,
    } = useModalState();

    const activeReport = payrollReports.find((pr) => pr.month === month);

    const setCurrentYear = useCallback(
        (newYear: string) => {
            if (hasValue(newYear)) {
                dispatch(setPayrollReportSelectedYear(newYear));
                query.set(QUERY_PARAM.YEAR, newYear);
                setQuery(query);
            }
        },
        [dispatch, query, setQuery]
    );
    const setCurrentMonth = useCallback(
        (newMonth: number | string) => {
            if (hasValue(newMonth)) {
                setMonth(Number(newMonth));
                query.set(QUERY_PARAM.MONTH, newMonth.toString());
                setQuery(query);
            }
        },
        [query, setQuery]
    );

    useEffect(() => {
        if (shouldInitializeWithMostRecentPayrollReport) {
            dispatch(getMonthAndYearOfMostRecentPayrollReport(teamId));
        }
    }, [dispatch, shouldInitializeWithMostRecentPayrollReport, teamId]);
    useEffect(() => {
        if (Number(monthQueryParam) === month && yearQueryParam === selectedYear) {
            return;
        }

        if (
            hasValue(monthQueryParam) &&
            inRange(+monthQueryParam, 1, MONTH_MAX + 1) &&
            hasValue(yearQueryParam)
        ) {
            setCurrentMonth(Number(monthQueryParam));
            setCurrentYear(yearQueryParam);
        } else if (isRh && activeDate) {
            const currentDate = new Date();
            const convertedActiveDate = new Date(activeDate);
            setCurrentMonth(
                currentDate > convertedActiveDate
                    ? currentDate.getMonth() + 1
                    : convertedActiveDate.getMonth() + 1
            );
            setCurrentYear(
                currentDate > convertedActiveDate
                    ? currentDate.getFullYear().toString()
                    : convertedActiveDate.getFullYear().toString()
            );
        } else if (
            mostRecentPayrollReportYearAndMonth &&
            hasValue(mostRecentPayrollReportYearAndMonth.month) &&
            hasValue(mostRecentPayrollReportYearAndMonth.year)
        ) {
            setCurrentMonth(mostRecentPayrollReportYearAndMonth.month);
            setCurrentYear(mostRecentPayrollReportYearAndMonth.year.toString());
        }
    }, [
        activeDate,
        isRh,
        month,
        monthQueryParam,
        mostRecentPayrollReportYearAndMonth,
        selectedYear,
        setCurrentMonth,
        setCurrentYear,
        yearQueryParam,
    ]);
    useEffect(() => {
        if (hasValue(selectedYear) && canGetPayrollReportsForYear) {
            dispatch(getPayrollReportsForYear(teamId, +selectedYear, cancelTokenSource?.token));
            dispatch(getTeamOperationsInfo(teamId, Number(selectedYear)));
            setShowTaxCodeColumns(false);
        }
    }, [dispatch, teamId, selectedYear, cancelTokenSource, canGetPayrollReportsForYear]);

    useEffect(() => () => dispatch(setPayrollReportSelectedYear('')), [dispatch]);

    const closeModal = (newYear?: string, newMonth?: number) => {
        if (newYear !== undefined && newMonth !== undefined) {
            setCurrentYear(newYear);
            setCurrentMonth(newMonth);
        }
        closeGenerateReportModal();
    };
    const data = useMemo(
        () =>
            (activeReport?.payrollReportUserSnapshots ?? []).map((x) =>
                createParentRow(x, activeReport)
            ),
        [activeReport]
    );

    const isIchra = data.some((r) => hasValue(r.ichraReimbursement));
    const hasTaxCodeData = data.some((r: IPayrollReportRow) =>
        hasValue(r.lineFourteenCode ?? r.lineSixteenCode ?? r.lineSeventeenCode)
    );

    useEffect(() => {
        if (!hasTaxCodeData || !isAle || !team?.show1095CCoding) {
            setShowTaxCodeColumns(false);
        }
    }, [data, hasTaxCodeData, isAle, month, team?.show1095CCoding, teamId]);

    const showPayrollSystemIdColumn = useMemo(() => data.some((r) => hasValue(r.payrollSystemId)), [
        data,
    ]);
    const showWorkLocationColumn = useMemo(() => data.some((r) => hasValue(r.workLocation)), [
        data,
    ]);
    const showTaxCodeSwitch = hasTaxCodeData && isAle && team?.show1095CCoding;
    const showWageUpColumn = useMemo(() => data.some((r) => hasValue(r.wageUp)), [data]);

    const { canApplyDeductions } = useHrsForPayrollReport(activeReport);
    const showSetPayrollDeductionsColumn = canApplyDeductions;

    const columns = useMemo<Column<IPayrollReportRow>[]>(() => {
        const isLocked = !!activeReport?.isLocked;
        const showMajorMedicalBenefitTypeColumn = activeReport?.payrollReportUserSnapshots?.some(
            (prus) => hasContents(prus.majorMedicalPlans)
        );
        const includeHsaContribution = activeReport?.payrollReportUserSnapshots?.some((prus) =>
            hasValue(prus.hsaEmployerContribution)
        );
        const showFsaColumn = activeReport?.payrollReportUserSnapshots?.some((prus) =>
            hasValue(prus.fsaEmployerContribution)
        );
        const showPreTaxColumn = data.some((r) => hasContents(r.preTaxBenefits));
        const hasIchraReimbursement = data.some((r) => (r.ichraReimbursement ?? 0) > 0);
        const cols: Column<IPayrollReportRow>[] = [
            getMemberColumn(
                isLocked,
                showPayrollSystemIdColumn,
                showMajorMedicalBenefitTypeColumn,
                getColumnWidth(data, (x) => x.payrollSystemId, 'Payroll System ID', {
                    magicSpacing: 10,
                }),
                showSetPayrollDeductionsColumn,
                showWorkLocationColumn
            ),
        ];
        if (showTaxCodeColumns && team?.show1095CCoding) {
            cols.push(getTaxCodesColumn(isIchra));
        }
        if (showWageUpColumn) {
            cols.push(getWageUpColumn(isLocked));
        }
        if (hasIchraReimbursement) {
            cols.push(getIchraReimbursementColumn(isLocked));
        }
        cols.push(getHsaColumn(isLocked, includeHsaContribution));
        if (showFsaColumn) {
            cols.push(getFsaColumn(isLocked));
        }
        if (hasIchraReimbursement && data.some((r) => hasContents(r.reimbursablePremiums))) {
            cols.push(getReimbursablePremiumsColumn(isLocked));
        }
        if (hasIchraReimbursement) {
            cols.push(getTaxFreeReimbursementsColumn(isLocked));
        }
        if (showPreTaxColumn) {
            cols.push(getPreTaxWithholdingColumn(isLocked));
        }
        cols.push(getPostTaxWithholdingColumn(isLocked));
        return cols;
    }, [
        activeReport?.isLocked,
        activeReport?.payrollReportUserSnapshots,
        data,
        isIchra,
        showPayrollSystemIdColumn,
        showSetPayrollDeductionsColumn,
        showTaxCodeColumns,
        showWageUpColumn,
        showWorkLocationColumn,
        team?.show1095CCoding,
    ]);

    const initialFilters: Filters<IPayrollReportRow> = useMemo(
        () => [
            {
                id: MEMBER_STATUS_COLUMN_ID,
                value: hasContents(statusQueryParam) ? statusQueryParam : USER_STATUS_OPTIONS,
            },
            {
                id: COVERAGE_LEVEL_COLUMN_ID,
                value: hasContents(coverageLevelQueryParam)
                    ? coverageLevelQueryParam
                    : COVERAGE_LEVEL_OPTIONS,
            },
            {
                id: MAJOR_MEDICAL_BENEFIT_TYPE_COLUMN_ID,
                value: hasContents(majorMedicalBenefitTypeQueryParam)
                    ? majorMedicalBenefitTypeQueryParam
                    : MAJOR_MEDICAL_BENEFIT_TYPE_OPTIONS,
            },
        ],
        [coverageLevelQueryParam, majorMedicalBenefitTypeQueryParam, statusQueryParam]
    );
    return (
        <React.Fragment>
            {isGenerateReportModalVisible && (
                <GeneratePayrollReportModal
                    close={closeModal}
                    initialMonth={month}
                    initialYear={selectedYear}
                />
            )}
            <Paper sx={{ p: 2 }}>
                <PayrollReportsPageHeader
                    activeReport={activeReport}
                    month={month}
                    setMonth={setMonth}
                />
            </Paper>
            <Paper sx={{ my: 1, p: 1 }}>
                <TextField
                    data-cy="search"
                    name="search"
                    onChange={onChange(setSearch)}
                    placeholder={`Filter Payroll Report results by member's first/last name${
                        showWorkLocationColumn ? '/work location' : ''
                    }${showPayrollSystemIdColumn ? ' or payroll system id' : ''}`}
                    type="search"
                    value={search}
                />
            </Paper>
            <Paper sx={{ p: 2 }}>
                <Skeleton count={4} height="400px" isEnabled={isLoading} sx={{ mb: 2 }}>
                    {isUndefined(activeReport?.teamId) ? (
                        <NoRowsMessage
                            actionText="Generate a report using the actions drop down above or the button below."
                            buttonText="Generate"
                            canTakeAction={canEditReport}
                            onAction={openGenerateReportModal}
                            text={`Payroll Report not available for ${
                                MONTHS[month - 1]?.name
                            } ${selectedYear}`}
                        />
                    ) : (
                        <Stack>
                            {showTaxCodeSwitch && (
                                <Stack alignItems="center" direction="row">
                                    <Switch
                                        color="secondary"
                                        onChange={() => setShowTaxCodeColumns(!showTaxCodeColumns)}
                                    />
                                    <Typography variant="body2">
                                        Show 1095-C coding recommendations
                                    </Typography>
                                </Stack>
                            )}

                            <StyledReactTable
                                columns={columns}
                                data={data}
                                filterTypes={filterTypes}
                                getCustomRowProps={getCustomRowProps}
                                getSubRows={getSubRows}
                                globalFilter={search}
                                globalFilterType={GLOBAL_FILTER_ID}
                                initialFilters={initialFilters}
                                initialSortBy={initialSortBy}
                                loading={isLoading}
                                pageSize={DEFAULT_PAGE_SIZE}
                            />
                        </Stack>
                    )}
                </Skeleton>
            </Paper>
        </React.Fragment>
    );
};

export default hot(module)(PayrollReportsPage);
