import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Box, { BoxProps } from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { clearSubmittedExpense } from 'actions/clear';
import {
    CALCULATE_SUBMITTED_EXPENSE_REIMBURSEMENT_AMOUNT_ACTION,
    calculateSubmittedExpenseReimbursementAmount,
} from 'actions/submittedExpenses/calculateSubmittedExpenseReimbursementAmount';
import { clearSubmittedExpenseAssignableReimbursementReports } from 'actions/submittedExpenses/clearAssignableReimbursementReports';
import {
    EDIT_SUBMITTED_EXPENSE_ACTION,
    editSubmittedExpense,
} from 'actions/submittedExpenses/editSubmittedExpense';
import { getSubmittedExpenseAssignableReimbursementReports } from 'actions/submittedExpenses/getAssignableReimbursementReports';
import { getMemberReimbursementUtilizations } from 'actions/submittedExpenses/getMemberReimbursementUtilizations';
import {
    GET_SUBMITTED_EXPENSE_ACTION,
    getSubmittedExpense,
} from 'actions/submittedExpenses/getSubmittedExpense';
import { getSubmittedExpenseModalUtilizations } from 'actions/submittedExpenses/getSubmittedExpenseModalUtilizations';
import {
    RESUBMIT_SUBMITTED_EXPENSE_ACTION,
    resubmitSubmittedExpense,
} from 'actions/submittedExpenses/resubmitSubmittedExpense';
import { AssignableReportOptions, ExpenseStatus } from 'api/generated/enums';
import { IRhFileDto, ISubmittedExpenseDto } from 'api/generated/models';
import { EditLockedSubmittedExpense, EditSubmittedExpense } from 'api/generated/permissions';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import ConditionalTooltip from 'components/ConditionalTooltip';
import CurrencyTextField from 'components/CurrencyTextField';
import Form from 'components/Form';
import InformationIconTooltip from 'components/InformationIconTooltip';
import RadioGroup from 'components/RadioGroup';
import Skeleton from 'components/Skeleton';
import TypedConfirmationInline from 'components/TypedConfirmationInline';
import Typography from 'components/Typography';
import { ExpenseTypeIds } from 'constants/reimbursementProgramExpenseTypes';
import useForm from 'hooks/useForm';
import useQuery from 'hooks/useQuery';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { debounce } from 'lodash';
import startCase from 'lodash/startCase';
import ExpenseTypeUtilization from 'pages/reimbursementExpenseManagement/ExpenseTypeUtilization';
import DecisionSummary from 'pages/reimbursementExpenseManagement/submittedExpenseModal/DecisionSummary';
import ReimbursementReportSelect from 'pages/reimbursementExpenseManagement/submittedExpenseModal/ReimbursementReportSelect';
import SubmittedExpenseDisclaimerText from 'pages/reimbursementExpenseManagement/submittedExpenseModal/SubmittedExpenseDisclaimerText';
import SubmittedExpenseFiles from 'pages/reimbursementExpenseManagement/submittedExpenseModal/SubmittedExpenseFiles';
import SubmittedExpenseModalSectionOne from 'pages/reimbursementExpenseManagement/submittedExpenseModal/SubmittedExpenseModalSectionOne';
import React, { useCallback, useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { hasApiActivity, hasCompletedRequest } from 'selectors/activity';
import { isRhSelector, isTeamAdminSelector, isTeamPayrollAdminSelector } from 'selectors/role';
import { enumToNameValueArray, hasValue, stringToFloat, stringToInt, toBoolean } from 'utilities';
import { convertFilesToFileAndName } from 'utilities/files';
import { formatCurrency, formatCurrencyOptionalDecimal } from 'utilities/format';
import { onChange as onFormChange } from 'utilities/forms';
import { boolean, number, object, ref, string } from 'yup';

export const SUBMITTED_EXPENSE_MODAL_PARAM = 'submittedExpense';
const ONE_CENT = 0.01;
const MAX_AMOUNT = 9_999_999.99;

const DECISION_SUMMARY_MAX_LENGTH = 4_000;
const FIVE_HUNDRED_MILLISECONDS = 500;
const EXPENSE_STATUS_OPTIONS = (enumToNameValueArray(ExpenseStatus, {
    formatName: startCase,
    nameKey: 'label',
}) as unknown) as { label: string; value: ExpenseStatus }[];

const TEXT_TO_MATCH = 'Override locked expense';
const TEXT_TO_MATCH_REGEX = new RegExp(`^${TEXT_TO_MATCH}$`);
const TEXT_DOES_NOT_MATCH_MESSAGE =
    'Input text must match the bolded phrase above exactly to confirm this action';
const TEXT_CONFIRMATION_MESSAGE =
    'This expense has been locked, are you sure you want to edit this?';
type LabelAndValueProps = {
    isCurrency?: boolean;
    isLoading: boolean;
    label: string;
    title?: string;
    value: number | string | undefined;
};

export const SubmittedExpenseLabelAndValue = (props: LabelAndValueProps) => (
    <React.Fragment>
        <Typography fontWeight="bold">
            {props.label} {props.title && <InformationIconTooltip title={props.title} />}
        </Typography>
        <Typography isLoading={props.isLoading}>
            {props.isCurrency ? formatCurrencyOptionalDecimal(props.value) : props.value}
        </Typography>
    </React.Fragment>
);

export const TwoColumns = (props: BoxProps) => (
    <Box display="grid" gap={2} gridTemplateColumns="1fr 1fr" {...props} />
);

export const ThreeColumns = (props: BoxProps) => (
    <Box display="grid" gap={1} gridTemplateColumns="1fr 1fr 1fr" {...props} />
);

type ISubmittedExpenseModal = {
    onClose: () => void;
};

const hsaCheck = (reimburseToHsa: boolean | string, expenseTypeId: number) =>
    (typeof reimburseToHsa === 'string' ? reimburseToHsa === 'true' : reimburseToHsa) &&
    expenseTypeId === ExpenseTypeIds.Medical.valueOf();

const SubmittedExpenseModal = ({ onClose }: ISubmittedExpenseModal) => {
    const dispatch = useThunkDispatch();
    const [queryParams] = useQuery();
    const { team } = useTeamProps();
    const {
        assignableReimbursementReports,
        canEditLockedSubmittedExpense,
        canEditSubmittedExpense,
        hasCompletedInitialRequest,
        isCalculateLoading,
        isLoading,
        isReimbursementUtilizationSectionVisible,
        isRh,
        loggedInUserId,
        modalUtilizations,
        submittedExpense,
    } = useSelector((state: AppStore) => ({
        assignableReimbursementReports: state.assignableReimbursementReports,
        canEditLockedSubmittedExpense: hasSomePermissions(state, EditLockedSubmittedExpense),
        canEditSubmittedExpense: hasSomePermissions(state, EditSubmittedExpense),
        hasCompletedInitialRequest: hasCompletedRequest(state, GET_SUBMITTED_EXPENSE_ACTION),
        isCalculateLoading: hasApiActivity(
            state,
            CALCULATE_SUBMITTED_EXPENSE_REIMBURSEMENT_AMOUNT_ACTION
        ),
        isLoading: hasApiActivity(
            state,
            GET_SUBMITTED_EXPENSE_ACTION,
            EDIT_SUBMITTED_EXPENSE_ACTION,
            RESUBMIT_SUBMITTED_EXPENSE_ACTION
        ),
        isPayrollAdmin: isTeamPayrollAdminSelector(state),
        isReimbursementUtilizationSectionVisible: state.memberReimbursementUtilizations.length > 0,
        isRh: isRhSelector(state),
        isTeamAdmin: isTeamAdminSelector(state),
        loggedInUserId: state.login.userId,
        modalUtilizations: state.submittedExpenseModalUtilizations,
        submittedExpense: state.submittedExpense,
        useHsaReimbursement: state.userInfoToSubmitExpense.useHsaForReimbursement,
    }));
    const { currentUserId, isCurrent } = useUserProps();
    const [fileData, setFileData] = useState<IRhFileDto[] | undefined>();
    const [newFileData, setNewFileData] = useState<File | File[] | undefined>();
    const [filesToDelete, setFilesToDelete] = useState<string[]>([]);
    const [amountEligibleForReimbursement, setAmountEligibleForReimbursement] = useState<number>();
    const [expenseStatus, setExpenseStatus] = useState<ExpenseStatus>();
    const [savedExpenseStatus, setSavedExpenseStatus] = useState<ExpenseStatus>();
    const [expenseAmount, setExpenseAmount] = useState<number>(submittedExpense.expenseAmount);
    const [decisionSummary, setDecisionSummary] = useState('');
    const [reimburseToHsa, setReimburseToHsa] = useState<boolean>();
    const [reimbursementToHsa, setReimbursementToHsa] = useState<number>();
    const [reimbursementToWages, setReimbursementToWages] = useState<number>();
    const [isEdited, setIsEdited] = useState(false);
    const [confirmationText, setConfirmationText] = useState('');
    const [reimbursementReportGlobalId, setReimbursementReportGlobalId] = useState<
        string | undefined
    >(submittedExpense.reimbursementReportGlobalId);
    const [selectedReimbursementReportOption, setSelectedReimbursementReportOption] = useState<
        AssignableReportOptions
    >();
    const [isRecurring, setIsRecurring] = useState<boolean>();
    const [isArchived, setIsArchived] = useState<boolean | undefined>();
    const isHsaEligible = submittedExpense.isHsaEligible;

    const isDenied = expenseStatus?.toString() === ExpenseStatus.Denied.toString();
    const isApproved = expenseStatus?.toString() === ExpenseStatus.Approved.toString();
    const isApprovedOrDenied = isApproved || isDenied;
    const isInformationNeeded =
        expenseStatus?.toString() === ExpenseStatus.InformationNeeded.toString();
    const isHsa = hsaCheck(reimburseToHsa as boolean, submittedExpense.expenseTypeId);
    const isSubmitted = expenseStatus?.toString() === ExpenseStatus.Submitted.toString();
    const isSavedAsSubmitted =
        savedExpenseStatus?.toString() === ExpenseStatus.Submitted.toString();
    const isApprovedAndCanEdit = isApproved && canEditSubmittedExpense;
    const isSavedAsSubmittedOrInformationNeeded =
        isSavedAsSubmitted ||
        savedExpenseStatus?.toString() === ExpenseStatus.InformationNeeded.toString();
    const canViewSensitiveExpenseData = submittedExpense.userId === loggedInUserId || isRh;
    const isLocked = submittedExpense?.isLocked;
    const [initialSubmittedExpense, setInitialSubmittedExpense] = useState(
        {} as ISubmittedExpenseDto
    );
    const reimbursementReportFrequency = team?.reimbursementReportFrequencyId;

    const amountEligibleForReimbursementSchema = object({
        amountEligibleForReimbursement: number()
            .required()
            .max(
                expenseAmount,
                `Amount Eligible for Reimbursement cannot be greater than the Expense Amount of ${formatCurrencyOptionalDecimal(
                    expenseAmount
                )}`
            )
            .min(ONE_CENT, 'Amount Eligible for Reimbursement must be greater than $0')
            .transform(stringToFloat)
            .label('Amount Eligible for Reimbursement'),
    });

    const schema = object({
        amountEligibleForReimbursement: number()
            .isRequiredWhen(isApproved)
            .max(
                ref('expenseAmount'),
                `Amount Eligible for Reimbursement cannot be greater than the Expense Amount of ${formatCurrencyOptionalDecimal(
                    expenseAmount
                )}`
            )
            .min(ONE_CENT, 'Amount Eligible for Reimbursement must be greater than $0')
            .transform(stringToFloat)
            .label('Amount Eligible for Reimbursement'),
        confirmationText: string().when(['$isEdited', '$isLocked'], {
            is: true,
            then: string().matches(TEXT_TO_MATCH_REGEX, TEXT_DOES_NOT_MATCH_MESSAGE),
        }),
        decisionSummary: string()
            .isRequiredWhen(isApprovedOrDenied || isInformationNeeded)
            .trim()
            .max(DECISION_SUMMARY_MAX_LENGTH)
            .label('Decision Summary'),
        expenseAmount: number()
            .required()
            .transform(stringToFloat)
            .min(
                ONE_CENT,
                `Expense Amount must be greater than or equal to ${formatCurrency(ONE_CENT, {
                    includeDollarSign: true,
                    preserveDecimal: true,
                })}`
            )
            .max(
                MAX_AMOUNT,
                `Expense Amount must be less than or equal to ${formatCurrency(MAX_AMOUNT, {
                    includeDollarSign: true,
                    preserveDecimal: true,
                })}`
            )
            .label('Expense Amount'),
        expenseStatus: number()
            .required()
            .transform(stringToInt)
            .label('Status'),
        isRecurring: boolean()
            .transform(toBoolean)
            .required()
            .label('Recurring Expense'),
        reimbursementToHsa: number()
            .transform(stringToFloat)
            .label('Reimbursement to Hsa'),
        reimbursementToWages: number()
            .isRequiredWhen(isApproved)
            .transform(stringToFloat)
            .label('Reimbursement to Wages'),
        reimburseToHsa: boolean()
            .transform(toBoolean)
            .label('Reimburse to HSA'),
        selectedReimbursementReportOption: number()
            .isRequiredWhen(isApproved)
            .transform(stringToInt)
            .label('Reimbursement Report'),
    });
    const { errors: amountEligibleErrors, validate: amountEligibleValidate } = useForm(
        amountEligibleForReimbursementSchema
    );
    const { errors, validate } = useForm(schema);

    const getAssignableReports = useCallback(async () => {
        if (canEditSubmittedExpense && assignableReimbursementReports.length === 0) {
            await dispatch(
                getSubmittedExpenseAssignableReimbursementReports(submittedExpense.globalId)
            );
        }
    }, [
        assignableReimbursementReports,
        canEditSubmittedExpense,
        dispatch,
        submittedExpense.globalId,
    ]);

    const onExpenseAmountChange = onFormChange((value: number) => {
        setExpenseAmount(value);
        checkForChanges();
    });

    const onReimburseToHsaChange = onFormChange((value: boolean) => {
        setReimburseToHsa(value);
        if (
            Number(expenseStatus) === ExpenseStatus.Approved &&
            hasValue(amountEligibleForReimbursement)
        ) {
            calculateReimbursement(expenseAmount, amountEligibleForReimbursement, value);
        }
        checkForChanges();
    });

    const onAmountEligibleForReimbursementChange = onFormChange(async (value: number) => {
        const numberValue = Number(value);
        setAmountEligibleForReimbursement(value);
        const { isValid } = await amountEligibleValidate({
            amountEligibleForReimbursement: numberValue,
        });

        if (isValid) {
            calculateReimbursement(expenseAmount, numberValue, reimburseToHsa as boolean);
            checkForChanges();
        } else if (hasValue(reimbursementToHsa) || hasValue(reimbursementToWages)) {
            setReimbursementToHsa(undefined);
            setReimbursementToWages(undefined);
        }
    });

    const onExpenseStatusChange = onFormChange((value: ExpenseStatus) => {
        if (value !== ExpenseStatus.Submitted) {
            setIsArchived(false);
        }
        setExpenseStatus(value);
        setAmountEligibleForReimbursement(undefined);
        setReimbursementToHsa(undefined);
        setReimbursementToWages(undefined);
        checkForChanges();
    });

    const onReimbursementReportChange = onFormChange((value: number) => {
        setReimbursementReportGlobalId(
            assignableReimbursementReports[Number(value) + 1]?.reimbursementReportGlobalId
        );
        setSelectedReimbursementReportOption(value);
        checkForChanges();
    });

    const onIsRecurringChange = onFormChange((value: boolean) => {
        setIsRecurring(value);
        checkForChanges();
    });
    const onIsArchiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsArchived(event.target.checked);
        checkForChanges();
    };

    const checkForChanges = useCallback(() => {
        const amountEligibleChanged =
            hasValue(initialSubmittedExpense.amountEligibleForReimbursement) &&
            hasValue(amountEligibleForReimbursement) &&
            initialSubmittedExpense.amountEligibleForReimbursement !==
                Number(amountEligibleForReimbursement);
        const expenseAmountChanged =
            initialSubmittedExpense.expenseAmount !== Number(expenseAmount);
        const decisionSummaryChanged = initialSubmittedExpense.decisionSummary !== decisionSummary;
        const filesToDeleteChanged = filesToDelete.length > 0;
        const newFilesAdded = (newFileData as File[])?.length > 0;
        const expenseStatusChanged = initialSubmittedExpense.expenseStatus !== expenseStatus;
        const isRecurringChanged = initialSubmittedExpense.isRecurring !== isRecurring;
        const reimbursementReportChanged =
            (initialSubmittedExpense.reimbursementReportGlobalId ?? '') !==
            reimbursementReportGlobalId;
        const reimburseToHsaChanged = initialSubmittedExpense.reimburseToHsa !== reimburseToHsa;
        const isArchivedChanged = initialSubmittedExpense.isArchived !== isArchived;
        const reimbursementToHsaChanged =
            initialSubmittedExpense.reimbursementToHsa !== reimbursementToHsa;
        const reimbursementToWagesChanged =
            initialSubmittedExpense.reimbursementToWages !== reimbursementToWages;

        const hasChanges = [
            amountEligibleChanged,
            expenseAmountChanged,
            decisionSummaryChanged,
            expenseStatusChanged,
            isRecurringChanged,
            reimbursementReportChanged,
            filesToDeleteChanged,
            newFilesAdded,
            reimburseToHsaChanged,
            isArchivedChanged,
            reimbursementToHsaChanged,
            reimbursementToWagesChanged,
        ].some((x) => x === true);
        if (hasChanges) {
            setIsEdited(true);
        } else {
            setIsEdited(false);
        }
    }, [
        amountEligibleForReimbursement,
        decisionSummary,
        expenseAmount,
        expenseStatus,
        filesToDelete,
        initialSubmittedExpense,
        isRecurring,
        newFileData,
        reimbursementReportGlobalId,
        reimburseToHsa,
        reimbursementToHsa,
        reimbursementToWages,
        isArchived,
    ]);

    const calculateReimbursement = useCallback(
        (
            expenseAmountValue: number,
            amountEligibleForReimbursementValue: number,
            reimburseToHsa: boolean
        ) => {
            dispatch(
                calculateSubmittedExpenseReimbursementAmount(
                    submittedExpense.globalId,
                    expenseAmountValue,
                    amountEligibleForReimbursementValue,
                    reimburseToHsa ?? submittedExpense.reimburseToHsa
                )
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, submittedExpense.globalId]
    );

    const submitForm = async () => {
        const { globalId, teamId } = submittedExpense;
        const { data, isValid } = await validate(
            {
                amountEligibleForReimbursement,
                confirmationText,
                decisionSummary,
                expenseAmount,
                filesToDelete,
                globalId,
                isRecurring,
                reimbursementReportGlobalId,
                reimbursementToHsa,
                reimbursementToWages,
                reimburseToHsa,
                selectedReimbursementReportOption,
                teamId,
                expenseStatus:
                    !canEditSubmittedExpense && isInformationNeeded
                        ? ExpenseStatus.Resubmitted
                        : expenseStatus,
                fileData: newFileData,
            },
            {
                context: { isEdited, isLocked },
            }
        );
        if (isValid && !isCalculateLoading) {
            const filesAndNames = convertFilesToFileAndName(newFileData);
            if (currentUserId === submittedExpense.userId) {
                await dispatch(
                    resubmitSubmittedExpense(
                        submittedExpense.globalId,
                        submittedExpense.userId,
                        filesAndNames
                    )
                );
            } else {
                const formData = (data as Partial<ISubmittedExpenseDto>) as ISubmittedExpenseDto;
                formData.isArchived = !!isArchived;

                await dispatch(
                    editSubmittedExpense(
                        formData,
                        filesAndNames,
                        filesToDelete,
                        selectedReimbursementReportOption
                    )
                );
            }
            if (isReimbursementUtilizationSectionVisible && !isCurrent) {
                dispatch(
                    getMemberReimbursementUtilizations(
                        submittedExpense.userId,
                        submittedExpense.dateOfService.getYear().toString()
                    )
                );
            }
            onClose();
        }
    };

    const onChange = <T,>(setState: React.Dispatch<React.SetStateAction<T>>) =>
        onFormChange((value: T) => {
            setState(value);
            checkForChanges();
        });

    const selectedUtilization = modalUtilizations.find(
        (x) => x.expenseTypeId === submittedExpense?.expenseTypeId
    );
    const isUtilizationSummaryVisible =
        isRh && isSavedAsSubmittedOrInformationNeeded && hasValue(selectedUtilization);
    const isWagesReimbursementSummaryVisible =
        hasValue(reimbursementToWages) && hasValue(submittedExpense.wagesReimbursementSummary);
    const isHsaReimbursementSummaryVisible =
        hasValue(reimbursementToHsa) && hasValue(submittedExpense.hsaReimbursementSummary);
    const wagesSummary = submittedExpense?.wagesReimbursementSummary ?? '';
    const hsaSummary = submittedExpense?.hsaReimbursementSummary ?? '';

    const amountEligibleForReimbursementInput = (
        <CurrencyTextField
            data-cy="amount-eligible-for-reimbursement-field"
            disabled={isLocked && !canEditLockedSubmittedExpense}
            errors={
                amountEligibleErrors?.amountEligibleForReimbursement ||
                errors?.amountEligibleForReimbursement
            }
            isLoading={isCalculateLoading}
            label="Amount Eligible for Reimbursement"
            onChange={debounce(
                onAmountEligibleForReimbursementChange,
                Number(FIVE_HUNDRED_MILLISECONDS)
            )}
            value={amountEligibleForReimbursement ?? undefined}
        />
    );
    const reimbursementToWagesInput = (
        <ConditionalTooltip
            isDisabled={!isWagesReimbursementSummaryVisible}
            placement="bottom-start"
            title={wagesSummary}
        >
            <CurrencyTextField
                disabled
                errors={errors?.reimbursementToWages}
                helperText="This field is not editable and is calculated from Amount Eligible for Reimbursement"
                label="Reimbursement to Wages"
                onChange={onChange(setReimbursementToWages)}
                value={reimbursementToWages}
            />
        </ConditionalTooltip>
    );

    useEffect(() => {
        const globalId = queryParams.get(SUBMITTED_EXPENSE_MODAL_PARAM);
        if (hasValue(globalId)) {
            dispatch(getSubmittedExpense(globalId));
        }

        return () => {
            dispatch(clearSubmittedExpense());
        };
    }, [dispatch, queryParams]);

    useEffect(() => {
        if (Number(expenseStatus) === ExpenseStatus.Approved) {
            getAssignableReports();
            setSelectedReimbursementReportOption(
                assignableReimbursementReports.find(
                    (r) =>
                        r.reimbursementReportGlobalId ===
                        submittedExpense.reimbursementReportGlobalId
                )?.assignableReportOption
            );
            setReimbursementReportGlobalId(
                assignableReimbursementReports.find(
                    (r) =>
                        r.reimbursementReportGlobalId ===
                        submittedExpense.reimbursementReportGlobalId
                )?.reimbursementReportGlobalId
            );
        }
    }, [
        assignableReimbursementReports,
        expenseStatus,
        getAssignableReports,
        submittedExpense.globalId,
        submittedExpense.reimbursementReportGlobalId,
    ]);

    useEffect(() => {
        if (hasValue(submittedExpense?.userId) && hasValue(submittedExpense?.dateOfService)) {
            dispatch(
                getSubmittedExpenseModalUtilizations(
                    submittedExpense?.userId,
                    submittedExpense?.dateOfService.getYear().toString()
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, submittedExpense.dateOfService]);

    useEffect(() => {
        if (!hasValue(initialSubmittedExpense.globalId)) {
            setInitialSubmittedExpense(submittedExpense);
        }
    }, [initialSubmittedExpense.globalId, submittedExpense, submittedExpense.globalId]);

    useEffect(() => {
        setFileData(submittedExpense.files);
    }, [submittedExpense.files]);

    useEffect(() => {
        setAmountEligibleForReimbursement(submittedExpense.amountEligibleForReimbursement);
        setExpenseStatus(submittedExpense.expenseStatus);
        setSavedExpenseStatus(submittedExpense.expenseStatus);
        setExpenseAmount(submittedExpense.expenseAmount);
        setDecisionSummary(submittedExpense.decisionSummary ?? '');
        setIsRecurring(submittedExpense.isRecurring);
        setReimbursementReportGlobalId(submittedExpense.reimbursementReportGlobalId ?? '');
        setIsArchived(submittedExpense.isArchived ?? false);
    }, [
        submittedExpense.amountEligibleForReimbursement,
        submittedExpense.expenseAmount,
        submittedExpense.expenseStatus,
        submittedExpense.isArchived,
        submittedExpense.isHsaEligible,
        submittedExpense.isRecurring,
        submittedExpense.useHsaReimbursement,
        submittedExpense.decisionSummary,
        submittedExpense.reimbursementReportGlobalId,
    ]);

    useEffect(() => {
        setReimbursementToHsa(submittedExpense.reimbursementToHsa);
        setReimbursementToWages(submittedExpense.reimbursementToWages);
    }, [submittedExpense.reimbursementToHsa, submittedExpense.reimbursementToWages]);

    useEffect(() => {
        setReimburseToHsa(submittedExpense.reimburseToHsa);
    }, [submittedExpense.reimburseToHsa]);

    useEffect(
        () => () => {
            dispatch(clearSubmittedExpense());
            dispatch(clearSubmittedExpenseAssignableReimbursementReports());
        },
        [dispatch]
    );

    return (
        <Dialog fullWidth maxWidth="lg" onClose={onClose} open>
            <DialogTitle>
                Submitted Expense {submittedExpense?.expenseId}
                {isLocked && (
                    <LockOutlinedIcon
                        color="primary"
                        fontSize="medium"
                        sx={{ marginBottom: 0.5, marginInline: 0.5 }}
                    />
                )}
            </DialogTitle>
            <DialogContent dividers>
                <Form
                    id="submitted-expense-modal-form"
                    isLoading={isLoading || isCalculateLoading || !hasCompletedInitialRequest}
                    onSubmit={submitForm}
                >
                    <Stack gap={2}>
                        <SubmittedExpenseModalSectionOne
                            expenseAmount={expenseAmount}
                            expenseAmountError={errors?.expenseAmount}
                            handleExpenseAmountChange={onExpenseAmountChange}
                            handleIsRecurringChange={onIsRecurringChange}
                            handleReimburseToHsaChange={onReimburseToHsaChange}
                            IsRecurring={isRecurring}
                            isRecurringError={errors?.isRecurring}
                            reimburseToHsa={reimburseToHsa as boolean}
                            reimburseToHsaError={errors?.reimburseToHsa}
                            submittedExpense={submittedExpense}
                        />
                        {canViewSensitiveExpenseData && (
                            <SubmittedExpenseFiles
                                checkForChanges={checkForChanges}
                                existingFiles={fileData}
                                fileIdsToDelete={filesToDelete}
                                isLoading={isLoading}
                                newFiles={newFileData}
                                setFilesToBeDeleted={setFilesToDelete}
                                setNewFileData={setNewFileData}
                                submittedExpense={submittedExpense}
                            />
                        )}
                        {isUtilizationSummaryVisible && (
                            <React.Fragment>
                                <Divider />
                                <ExpenseTypeUtilization
                                    title="Reimbursement Program Summary"
                                    utilization={selectedUtilization}
                                />
                                <Typography variant="subtitle2">
                                    The reimbursement utilization shown is the members current
                                    utilization considering all approved expenses for the year up to
                                    today, not including this one.
                                </Typography>
                            </React.Fragment>
                        )}
                        <Divider />
                        {!canEditSubmittedExpense ? (
                            <TwoColumns gridTemplateColumns={{ md: '1fr 1fr' }}>
                                <TwoColumns>
                                    <SubmittedExpenseLabelAndValue
                                        isLoading={isLoading}
                                        label="Status"
                                        value={
                                            EXPENSE_STATUS_OPTIONS.find(
                                                (status) => status.value === expenseStatus
                                            )?.label
                                        }
                                    />
                                </TwoColumns>
                            </TwoColumns>
                        ) : (
                            <RadioGroup
                                disabled={isLocked && !canEditLockedSubmittedExpense}
                                errors={errors?.expenseStatus}
                                label="Status"
                                onChange={onExpenseStatusChange}
                                options={EXPENSE_STATUS_OPTIONS.filter(
                                    (status) =>
                                        status.value !== ExpenseStatus.Resubmitted ||
                                        savedExpenseStatus === ExpenseStatus.Resubmitted
                                )}
                                row
                                value={expenseStatus ?? ''}
                            />
                        )}
                        <ReimbursementReportSelect
                            assignedReimbursementReportOption={selectedReimbursementReportOption}
                            data-cy="reimbursement-report-dropdown"
                            errors={errors?.selectedReimbursementReportOption}
                            expenseStatus={expenseStatus}
                            onChange={onReimbursementReportChange}
                            reportOptions={assignableReimbursementReports}
                            submittedExpense={submittedExpense}
                        />
                        {(isApprovedOrDenied || isInformationNeeded) && (
                            <React.Fragment>
                                <Divider />
                                {isApprovedAndCanEdit && isHsa && (
                                    <ThreeColumns gridTemplateColumns={{ md: '1fr 1fr 1fr' }}>
                                        <Skeleton
                                            height={59.5}
                                            isEnabled={
                                                isLoading ||
                                                isCalculateLoading ||
                                                !hasCompletedInitialRequest
                                            }
                                            sx={{ mb: 2 }}
                                        >
                                            {amountEligibleForReimbursementInput}
                                        </Skeleton>
                                        <ConditionalTooltip
                                            isDisabled={!isHsaReimbursementSummaryVisible}
                                            placement="bottom-start"
                                            title={hsaSummary}
                                        >
                                            <CurrencyTextField
                                                disabled
                                                errors={errors?.reimbursementToHsa}
                                                helperText="This field is not editable and is calculated from Amount Eligible for Reimbursement"
                                                label="Reimbursement to HSA"
                                                onChange={onChange(setReimbursementToHsa)}
                                                value={reimbursementToHsa}
                                            />
                                        </ConditionalTooltip>
                                        {reimbursementToWagesInput}
                                    </ThreeColumns>
                                )}
                                {isApprovedAndCanEdit && !isHsa && (
                                    <TwoColumns gridTemplateColumns={{ md: '1fr 1fr' }}>
                                        {amountEligibleForReimbursementInput}
                                        {reimbursementToWagesInput}
                                    </TwoColumns>
                                )}
                                {isApproved && !canEditSubmittedExpense && (
                                    <TwoColumns gridTemplateColumns={{ md: '1fr 1fr' }}>
                                        <TwoColumns>
                                            <SubmittedExpenseLabelAndValue
                                                isCurrency
                                                isLoading={isLoading}
                                                label="Amount Eligible For Reimbursement"
                                                value={amountEligibleForReimbursement}
                                            />
                                            {isHsa && (
                                                <SubmittedExpenseLabelAndValue
                                                    isCurrency
                                                    isLoading={isLoading}
                                                    label="Reimbursement to HSA"
                                                    title={hsaSummary}
                                                    value={reimbursementToHsa}
                                                />
                                            )}
                                            <SubmittedExpenseLabelAndValue
                                                data-cy="reimbursement-to-wages-field"
                                                isCurrency
                                                isLoading={isLoading}
                                                label="Reimbursement To Wages"
                                                title={wagesSummary}
                                                value={reimbursementToWages}
                                            />
                                        </TwoColumns>
                                    </TwoColumns>
                                )}
                                <Divider />
                                {canViewSensitiveExpenseData && (
                                    <DecisionSummary
                                        decisionSummary={decisionSummary}
                                        decisionSummaryErrors={errors?.decisionSummary}
                                        handleDecisionSummary={onChange(setDecisionSummary)}
                                        isLoading={isLoading}
                                        isLocked={isLocked}
                                    />
                                )}
                                {isApproved && (
                                    <Typography
                                        className="justify-content-between mx-2 align-items-center"
                                        fontSize="12px"
                                    >
                                        <SubmittedExpenseDisclaimerText
                                            isHsaEligible={isHsaEligible}
                                            reimbursementReportFrequency={
                                                reimbursementReportFrequency
                                            }
                                            reimburseToHsa={reimburseToHsa}
                                            reportOptions={assignableReimbursementReports}
                                            selectedReimbursementReportOption={
                                                selectedReimbursementReportOption
                                            }
                                        />
                                    </Typography>
                                )}
                            </React.Fragment>
                        )}
                    </Stack>
                </Form>
                {isLocked && isEdited && canEditLockedSubmittedExpense && (
                    <TypedConfirmationInline
                        confirmationText={confirmationText}
                        errors={errors?.confirmationText}
                        onFormChange={onFormChange(setConfirmationText)}
                        textToMatch={TEXT_TO_MATCH}
                        typeConfirmationMessage={TEXT_CONFIRMATION_MESSAGE}
                    />
                )}
            </DialogContent>
            <DialogActions>
                {canEditSubmittedExpense && isSubmitted && (
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="space-between"
                        pl={1}
                        width="100%"
                    >
                        <Checkbox
                            checked={isArchived}
                            className="m-0"
                            label="Archive"
                            onChange={onIsArchiveChange}
                        />
                    </Stack>
                )}
                <Stack alignItems="center" direction="row">
                    <Button onClick={onClose} variant="text">
                        Close
                    </Button>
                    {(canEditSubmittedExpense || isInformationNeeded) &&
                        canViewSensitiveExpenseData && (
                            <Button
                                data-cy="submitted-expense-modal-form-button"
                                disabled={(isLocked && !canEditLockedSubmittedExpense) || !isEdited}
                                form="submitted-expense-modal-form"
                                isLoading={isLoading || isCalculateLoading}
                                type="submit"
                                variant="text"
                            >
                                Save
                            </Button>
                        )}
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default hot(module)(SubmittedExpenseModal);
