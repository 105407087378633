import Typography from '@mui/material/Typography';
import {
    TRANSITION_TO_CUSTOMER_ACTION,
    transitionToCustomer,
} from 'actions/team/transitionToTeamCustomer';
import Button from 'components/Button';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { AppStore } from 'reducers/appReducer';
import { TEAMS_PEOPLE_PATH } from 'routers/routes';
import { hasApiActivity } from 'selectors/activity';

const MEMBER_STATUS_PARAM = 'Member Status';
const statusString = 'Renewing';

const EndRenewalPeriodModal = ({ onClose, teamId }: { onClose: () => void; teamId: string }) => {
    const dispatch = useThunkDispatch();
    const { isLoading } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(state, TRANSITION_TO_CUSTOMER_ACTION),
    }));

    const transitionToCustomerStatus = useCallback(async () => {
        await dispatch(transitionToCustomer(teamId));
        onClose();
    }, [dispatch, onClose, teamId]);

    const search = new URLSearchParams();
    search.set(MEMBER_STATUS_PARAM, statusString);

    return (
        <Modal onHide={onClose} show>
            <Modal.Header closeButton>
                <Modal.Title>End Renewal Period?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Typography textAlign="center" variant="h5">
                    Are you sure you want to end the renewal period for this team?
                </Typography>
                <ul>
                    <li>
                        The team&apos;s status will change to <strong>Customer</strong>.
                    </li>
                    <li>Members will no longer be able to go through the renewal process.</li>
                    <li>
                        Active members that chose to waive for the member&apos;s active year will
                        have their <strong>Member Status</strong> updated to <strong>Waived</strong>
                        .
                    </li>
                    <li>
                        Members that were previously waived (<strong>Member Status</strong> of{' '}
                        <strong>Waived</strong>) that do not have an{' '}
                        <strong>Enrollment Status</strong> of <strong>Waived</strong> will be set as{' '}
                        <strong>Waived</strong> and sent the waived confirmation email.
                    </li>
                    <li>
                        If any members have a <strong>Member Status</strong> of{' '}
                        <strong>Renewing</strong>, they will need to be updated to{' '}
                        <strong>Active</strong> or <strong>Waived</strong> to transition the team.{' '}
                        <Link
                            to={{
                                pathname: generatePath(TEAMS_PEOPLE_PATH, { teamId }),
                                search: search.toString(),
                            }}
                        >
                            View team members in Renewing.
                        </Link>
                    </li>
                </ul>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Button onClick={onClose}>Cancel</Button>
                <Button isLoading={isLoading} onClick={transitionToCustomerStatus} type="submit">
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default hot(module)(EndRenewalPeriodModal);
