import {
    CLOSE_CUSTOM_REIMBURSEMENT_MODAL,
    CLOSE_CUSTOM_WAGEUP_MODAL,
    OPEN_CUSTOM_REIMBURSEMENT_MODAL,
    OPEN_CUSTOM_WAGEUP_MODAL,
} from 'pages/pathwayBlueprint/pathwayCustomProjectedModalActions';
import basicReducer from 'reducers/basicReducer';

export type IPathwayUserCustomProperties = {
    customProjectedReimbursement?: number;
    customProjectedReimbursementNote?: string;
    customProjectedWageUp?: number;
    customProjectedWageUpNote?: string;
    id: string;
};

export type IPathwayModalState = {
    isCustomReimbursementModalVisible: boolean;
    isCustomWageUpModalVisible: boolean;
    pathwayUser: IPathwayUserCustomProperties;
};
const initialState: IPathwayModalState = {
    isCustomReimbursementModalVisible: false,
    isCustomWageUpModalVisible: false,
    pathwayUser: ({} as unknown) as IPathwayUserCustomProperties,
};
export const pathwayCustomProjectedModalState = basicReducer(
    initialState,
    OPEN_CUSTOM_WAGEUP_MODAL,
    CLOSE_CUSTOM_WAGEUP_MODAL,
    OPEN_CUSTOM_REIMBURSEMENT_MODAL,
    CLOSE_CUSTOM_REIMBURSEMENT_MODAL
);
