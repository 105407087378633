import { Box, Grid } from '@mui/material';
import { PathwayTypes } from 'api/generated/enums';
import { ViewPathwayBlueprints } from 'api/generated/permissions';
import InvalidEmailPill from 'components/InvalidEmailPill';
import NeedsFollowUpPill from 'components/NeedsFollowUpPill';
import PageHeader from 'components/PageHeader';
import ProfileAttribute from 'components/ProfileAttribute';
import Select from 'components/Select';
import VipChip from 'components/chip/VipChip';
import ProfileActionButtons from 'components/profileActionButtons/ProfileActionButtons';
import { RH_TEAM_ID } from 'constants/teams';
import TeamManagementContext from 'contexts/TeamManagementContext';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import startCase from 'lodash/startCase';
import ProfilePicture from 'pages/profile/ProfilePicture';
import { setSelectedYear } from 'pages/profile/profileActions';
import React, { useCallback, useContext } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Link, generatePath } from 'react-router-dom';
import { AppStore } from 'reducers/appReducer';
import { TEAMS_PATHWAY_BLUEPRINT_PATH } from 'routers/routes';
import { hasSomePermissions } from 'selectors';
import { isAdvisorSelector, isRhSelector, isTeamAdminSelector } from 'selectors/role';
import { hasValue } from 'utilities';
import { getYears } from 'utilities/year';

const items = getYears(() => 1).map((x) => ({ name: `${x}` }));

const ProfileHeader = () => {
    const dispatch = useThunkDispatch();
    const { isTeamManagementPage } = useContext(TeamManagementContext);
    const { teamId } = useTeamProps();
    const { isCurrent, user, userProfile } = useUserProps();
    const {
        activePathwayBlueprintId,
        activePathwayBlueprintYear,
        canViewPathwayBlueprints,
        canViewVip,
        isRh,
        pathwayUserProfileInfo,
        selectedYear,
    } = useSelector((state: AppStore) => {
        const isRhProp = isRhSelector(state);
        return {
            activePathwayBlueprintId: state.pathwayUserProfileInfo.pathwayBlueprintId,
            activePathwayBlueprintYear:
                state.pathwayUserProfileInfo?.year ?? new Date().getFullYear(),
            canViewPathwayBlueprints: hasSomePermissions(state, ViewPathwayBlueprints),
            canViewVip: isRhProp || isAdvisorSelector(state) || isTeamAdminSelector(state),
            isRh: isRhProp,
            pathwayUserProfileInfo: state.pathwayUserProfileInfo,
            selectedYear: state.profileState.selectedYear,
        };
    });
    const onSelectedYearChange = useCallback(
        ({ target: { value } }) => dispatch(setSelectedYear(value)),
        [dispatch]
    );
    const pathwayAttributeValue =
        canViewPathwayBlueprints && hasValue(activePathwayBlueprintId) ? (
            <Link
                to={generatePath(TEAMS_PATHWAY_BLUEPRINT_PATH, {
                    teamId,
                    pathwayBlueprintId: activePathwayBlueprintId,
                })}
            >
                {pathwayUserProfileInfo?.pathwayName}
            </Link>
        ) : (
            pathwayUserProfileInfo?.pathwayName
        );
    return (
        <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', mb: 4 }}>
            <Grid sx={{ alignItems: 'center' }}>
                {!isTeamManagementPage && (
                    <PageHeader mb={1} mr={2} variant="h4">
                        {isCurrent ? 'My' : `${userProfile.user?.firstName}'s`} Profile
                        <NeedsFollowUpPill user={user} />
                        {canViewVip && user?.isVip && <VipChip sx={{ ml: 1 }} variant="outlined" />}
                        <InvalidEmailPill user={user} />
                    </PageHeader>
                )}
                <Select
                    data-cy="selected-profile-year"
                    items={items}
                    name="selectedYear"
                    onChange={onSelectedYearChange}
                    optionText="name"
                    optionValue="name"
                    value={selectedYear}
                />
                {isRh && teamId === RH_TEAM_ID && (
                    <Box sx={{ ml: 2 }}>
                        <ProfilePicture isCurrent={isCurrent} user={user} />
                    </Box>
                )}
            </Grid>
            {canViewPathwayBlueprints && (
                <ProfileAttribute
                    OverrideProps={
                        <PageHeader sx={{ ml: 4, mr: 2 }} variant="h4">
                            <Box>Pathway</Box>
                        </PageHeader>
                    }
                    textToCopy={pathwayAttributeValue}
                >
                    {hasValue(pathwayUserProfileInfo?.pathwayType) &&
                    selectedYear === activePathwayBlueprintYear.toString() ? (
                        <Box sx={{ textAlign: 'start' }}>
                            {pathwayAttributeValue}
                            {hasValue(
                                startCase(PathwayTypes[pathwayUserProfileInfo?.pathwayType])
                            ) && (
                                <Box sx={{ fontStyle: 'bold' }}>
                                    {startCase(PathwayTypes[pathwayUserProfileInfo?.pathwayType])}
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <Box>Unknown</Box>
                    )}
                </ProfileAttribute>
            )}
            <ProfileActionButtons profile={userProfile} shouldRedirect />
        </Grid>
    );
};

export default hot(module)(ProfileHeader);
