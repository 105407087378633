import { Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { getCompletedUserTasks } from 'actions/task/getCompletedUserTasks';
import {
    SELECT_ANCILLARY_BENEFITS_ACTION,
    selectAncillaryBenefits,
} from 'actions/teamBenefit/selectAncillaryBenefits';
import { TeamBenefitTypes, UserTeamBenefitTermDetailStatuses } from 'api/generated/enums';
import {
    ISelectAncillaryBenefitRequest,
    SelectAncillaryBenefitRequest,
} from 'api/generated/models';
import Button from 'components/Button';
import Form from 'components/Form';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { getHouseholdMemberDataForAncillarySelection } from 'pages/dashboard/ancillaryBenefitSelection/AncillaryDisplayItem';
import {
    ANCILLARY_TYPES,
    areAllSelectionsMade,
    descriptionText,
    getAncillaryDisplayItems,
} from 'pages/dashboard/ancillaryBenefitSelection/AncillarySelectionUtilities';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities/index';

type IAncillarySelectionModalProps = {
    isReadonly: boolean;
    onClose: () => void;
};

const AncillarySelectionModal = ({ onClose, isReadonly }: IAncillarySelectionModalProps) => {
    const { ancillaries, householdMembers, isLoading } = useSelector((state: AppStore) => ({
        ancillaries: state.ancillaryBenefits,
        householdMembers: state.householdMembers,
        isLoading: hasApiActivity(state, SELECT_ANCILLARY_BENEFITS_ACTION),
    }));
    const dispatch = useThunkDispatch();
    const { userId, isCurrent } = useUserProps();
    const [ancillaryDecisions, setAncillaryDecisions] = useState(
        ancillaries.map((ancillary) => {
            const {
                initiallyCoveredForOfferLevelIds,
            } = getHouseholdMemberDataForAncillarySelection(householdMembers, ancillary);
            return {
                householdMemberIds: initiallyCoveredForOfferLevelIds,
                statusId: ancillary.statusId,
                teamBenefitType: ancillary.teamBenefitTypeId,
                userTermDetailId: ancillary.userTermDetailId,
            };
        }) as ISelectAncillaryBenefitRequest[]
    );
    const [canSave, setCanSave] = useState(areAllSelectionsMade(ancillaryDecisions));

    const handleAncillaryDecisionsChange = (
        ancillaryDecisions: ISelectAncillaryBenefitRequest[],
        ancillaryId: string,
        type: TeamBenefitTypes,
        newStatus: UserTeamBenefitTermDetailStatuses
    ) => {
        setCanSave(areAllSelectionsMade(ancillaryDecisions));
        const newAncillaryDecisions = ancillaryDecisions.map((a) => {
            if (
                newStatus !== UserTeamBenefitTermDetailStatuses.Waived &&
                a.userTermDetailId !== ancillaryId &&
                a.teamBenefitType === type
            ) {
                return {
                    ...a,
                    statusId: UserTeamBenefitTermDetailStatuses.Waived,
                };
            } else {
                return a;
            }
        });
        setAncillaryDecisions(newAncillaryDecisions);
    };

    const getAncillaryDisplayItemsProps = {
        ancillaries,
        ancillaryDecisions,
        handleAncillaryDecisionsChange,
        isReadonly,
    };

    const onSubmit = async () => {
        const ancillaryDecisionsToSend = ancillaryDecisions.map(
            (r) => new SelectAncillaryBenefitRequest(r)
        );

        await dispatch(selectAncillaryBenefits(userId, ancillaryDecisionsToSend, isCurrent));
        dispatch(getCompletedUserTasks());
        onClose();
    };
    const shouldShowPrepopulatedMessage =
        ancillaries.some((a) => a.statusId != UserTeamBenefitTermDetailStatuses.Offered) &&
        !isReadonly;

    useEffect(() => {
        const hasMultipleAcceptedAncillaries = ancillaries
            .flatMap((ancillary) => {
                if (
                    [
                        UserTeamBenefitTermDetailStatuses.Enrolled,
                        UserTeamBenefitTermDetailStatuses.Submitted,
                    ].includes(ancillary.statusId)
                ) {
                    return [
                        {
                            teamBenefitType: ancillary.teamBenefitType,
                            teamBenefitTypeId: ancillary.teamBenefitTypeId,
                        },
                    ];
                }
                return [];
            })
            .reduce((acc: Record<string, number>, { teamBenefitType }) => {
                if (hasValue(teamBenefitType)) {
                    acc[teamBenefitType] = (acc[teamBenefitType] ?? 0) + 1;
                }
                return acc;
            }, {});

        setCanSave(
            !Object.values(hasMultipleAcceptedAncillaries).some((count) => (count) > 1) &&
                areAllSelectionsMade(ancillaryDecisions)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Dialog fullWidth maxWidth="md" onClose={onClose} open scroll="paper">
            <DialogTitle>
                <Typography textAlign="center" variant="h2">
                    {`${isReadonly ? 'View' : 'Select'} Your Ancillary Benefits`}
                </Typography>
                {descriptionText(isReadonly)}
                {shouldShowPrepopulatedMessage && (
                    <Alert severity="warning">
                        We&apos;ve pre-populated this form with your current ancillary selections.
                    </Alert>
                )}
            </DialogTitle>
            <DialogContent dividers sx={{ paddingInline: 4 }}>
                <Form id="ancillary-selection-modal-form" isLoading={isLoading} onSubmit={onSubmit}>
                    <Grid columnGap={3} container rowGap={1.5}>
                        {ANCILLARY_TYPES.map((type) =>
                            getAncillaryDisplayItems(getAncillaryDisplayItemsProps, type)
                        )}
                    </Grid>
                </Form>
            </DialogContent>
            <DialogActions sx={{ margin: 1.5 }}>
                <Stack direction="row" spacing={2}>
                    <Button onClick={onClose} variant="outlined">
                        {isReadonly ? 'Close' : 'Cancel'}
                    </Button>
                    {!isReadonly && (
                        <Button
                            disabled={!canSave}
                            form="ancillary-selection-modal-form"
                            isLoading={isLoading}
                            type="submit"
                            variant="contained"
                        >
                            Submit
                        </Button>
                    )}
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default hot(module)(AncillarySelectionModal);
