import LoadingButton from '@mui/lab/LoadingButton';
import { Card, CardContent, Grid, Typography, useTheme } from '@mui/material';
import { ADD_SELECTED_PLAN_ACTION } from 'actions/selectedPlan/addSelectedPlan';
import { IMarketplacePlanDto, IMediSharePlan, MarketplacePlanDto } from 'api/generated/models';
import { BODY_FONT_COLOR } from 'constants/styleConstants';
import useUserProps from 'hooks/useUserProps';
import { find } from 'lodash';
import { PersonOnPlan } from 'pages/selections/SelectionCard';
import PlanCardHeader, { IPlanCardHeaderProps } from 'pages/shop/PlanCardHeader';
import PlanCardLeftFooter from 'pages/shop/PlanCardLeftFooter';
import { IShoppingPlan } from 'pages/shop/shopping';
import React, { ReactNode, useMemo } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivityWithParams } from 'selectors/activity';
import { getDisplayFirstName } from 'utilities/household';
import { hasContents, hasValue } from 'utilities/index';

export const getDataCyPathwayPlanPrefix = (dataCyPrefix: string | undefined) =>
    hasValue(dataCyPrefix) ? `${dataCyPrefix}-` : '';
const PlanCardBase = ({
    benefitDetailsButtonHref,
    benefitDetailsButtonText,
    cost,
    costWithCredit,
    dataCyPrefix,
    handleSelectPlan,
    hidePlanCardContent,
    hideProviders,
    hideSelectPlan,
    hideYourCost,
    householdMembersCovered,
    isChunkPlan,
    isDisabled,
    isIchraFlowShopping,
    isPrimaryCovered,
    name,
    pathwayPlanCardHeader,
    plan,
    planCardContent,
    selectButtonText,
    recommendedPlanDescription,
    recommendedPlanLabel,
    showRecommendedPlanBookmark,
    showTaxSavings,
}: IPlanCardHeaderProps & {
    benefitDetailsButtonHref: string | undefined;
    benefitDetailsButtonText: string;
    dataCyPrefix?: string;
    handleSelectPlan?: (plan: Partial<IShoppingPlan>) => Promise<void>;
    hidePlanCardContent?: boolean;
    hideProviders?: boolean;
    hideSelectPlan?: boolean;
    householdMembersCovered?: string[];
    isChunkPlan?: boolean;
    isDisabled?: boolean;
    isIchraFlowShopping?: boolean;
    isPrimaryCovered?: boolean;
    pathwayPlanCardHeader?: JSX.Element;
    planCardContent: JSX.Element;
    recommendedPlanDescription?: string;
    recommendedPlanLabel?: string;
    selectButtonText: string;
    showRecommendedPlanBookmark?: boolean;
}) => {
    const theme = useTheme();
    const { user } = useUserProps();
    const { householdMembers, isSelectingPlan } = useSelector((state: AppStore) => ({
        householdMembers: state.householdMembers,
        isSelectingPlan: hasApiActivityWithParams(state, ADD_SELECTED_PLAN_ACTION, {
            planId: (plan as IMediSharePlan).ahp ?? ((plan as IMarketplacePlanDto).id as string),
        }),
    }));

    const planFor = useMemo(() => {
        let personsOnPlan: ReactNode[] = [];
        if (
            hasContents(householdMembers) &&
            hasValue(householdMembersCovered) &&
            householdMembersCovered !== undefined
        ) {
            personsOnPlan = householdMembersCovered.map((id) => (
                <Grid item key={id}>
                    <PersonOnPlan
                        name={find(householdMembers, (x) => x.householdMemberId === id)?.firstName}
                    />
                </Grid>
            ));
        }
        if (isPrimaryCovered) {
            personsOnPlan.unshift(
                <Grid item key={1}>
                    <PersonOnPlan isPrimary name={getDisplayFirstName(user)} />
                </Grid>
            );
        }
        return personsOnPlan;
    }, [householdMembers, householdMembersCovered, isPrimaryCovered, user]);

    const householdMemberContent = hasValue(householdMembersCovered) ? (
        <React.Fragment>
            <Typography variant="body2">This plan is for: </Typography>
            {planFor}
        </React.Fragment>
    ) : (
        <React.Fragment></React.Fragment>
    );

    const handleClick = () => {
        handleSelectPlan?.(plan);
    };

    return (
        <Card
            data-cy={`${getDataCyPathwayPlanPrefix(dataCyPrefix)}plan-card`}
            sx={isChunkPlan ? { border: `8px ${theme.palette.secondary.main} solid` } : {}}
        >
            <CardContent sx={{ color: BODY_FONT_COLOR }}>
                {pathwayPlanCardHeader ?? (
                    <PlanCardHeader
                        carrier={
                            plan.isMarketplace
                                ? (plan as MarketplacePlanDto).issuer?.name
                                : plan.isMediShare
                                ? 'Medi-Share'
                                : ''
                        }
                        cost={cost}
                        costWithCredit={costWithCredit}
                        hideYourCost={hideYourCost}
                        isChunkPlan={isChunkPlan}
                        name={name}
                        plan={plan}
                        recommendedPlanDescription={recommendedPlanDescription}
                        recommendedPlanLabel={recommendedPlanLabel}
                        showRecommendedPlanBookmark={showRecommendedPlanBookmark}
                        showTaxSavings={showTaxSavings}
                    />
                )}
                {!hidePlanCardContent && (
                    <React.Fragment>
                        <hr />
                        <div className="justify-content-between">{planCardContent}</div>
                    </React.Fragment>
                )}
                <hr />
                <Grid columnSpacing={2} container direction="row" item>
                    <Grid
                        alignItems="center"
                        columnSpacing={1}
                        container
                        direction="row"
                        item
                        justifyContent="flex-start"
                        marginLeft={1}
                    >
                        {householdMemberContent}
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <PlanCardLeftFooter
                            hideProviders={hideProviders}
                            isIchraFlowShopping={isIchraFlowShopping}
                            plan={plan}
                        />
                    </Grid>
                    <Grid alignSelf="center" container item justifyContent="center" md={4} xs={12}>
                        {hasValue(benefitDetailsButtonHref) && (
                            <a
                                data-cy={`${getDataCyPathwayPlanPrefix(dataCyPrefix)}plan-details`}
                                href={benefitDetailsButtonHref}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                {benefitDetailsButtonText}
                            </a>
                        )}
                    </Grid>
                    {!hideSelectPlan && (
                        <Grid
                            alignItems="flex-end"
                            container
                            item
                            justifyContent="flex-end"
                            md={4}
                            paddingRight={2}
                            xs={12}
                        >
                            <Grid item>
                                <LoadingButton
                                    data-cy={`select-${getDataCyPathwayPlanPrefix(
                                        dataCyPrefix
                                    )}plan`}
                                    disabled={isDisabled}
                                    loading={isSelectingPlan}
                                    onClick={handleClick}
                                    variant="outlined"
                                >
                                    {selectButtonText}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default hot(module)(PlanCardBase);
