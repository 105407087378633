import { Stack, Typography } from '@mui/material';
import ProfileAttribute from 'components/ProfileAttribute';
import React from 'react';

const PaymentsContentBlock = ({
    content,
    title,
}: {
    content: { id?: number | string; label: string; value: React.ReactNode | string }[];
    title?: string;
}) => (
    <React.Fragment>
        <Stack spacing={2}>
            {title && (
                <Typography fontWeight="bold" gutterBottom textAlign="left" variant="h4">
                    {title}
                </Typography>
            )}
            {content.map((item) => (
                <ProfileAttribute key={item.id ?? item.label} label={item.label}>
                    {item.value}
                </ProfileAttribute>
            ))}
        </Stack>
    </React.Fragment>
);

export default PaymentsContentBlock;
