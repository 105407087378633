import { Dialog, DialogContent, DialogTitle, Divider, Grid, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { GET_HOUSEHOLD_MEMBERS_ACTION } from 'actions/householdMember/getHouseholdMembers';
import { getLatestYearForRates } from 'actions/marketplacePlan/getLatestYearForRates';
import {
    ADD_CUSTOM_SELECTED_PLANS_ACTION,
    addCustomSelectedPlans,
} from 'actions/selectedPlan/addCustomSelectedPlans';
import { deleteSelectedPlan } from 'actions/selectedPlan/deleteSelectedPlan';
import {
    GET_SELECTED_PLANS_FOR_RENEWAL_ACTION,
    getSelectedPlansForRenewal,
} from 'actions/selectedPlan/getSelectedPlansForRenewal';
import {
    SAVE_COMPLETED_USER_TASK_ACTION,
    saveCompletedUserTask,
} from 'actions/task/saveCompletedUserTask';
import { getTeamOperationsInfo } from 'actions/team/getTeamOperationsInfo';
import { getUserProfile } from 'actions/user/getUserProfile';
import { PATCH_USER_ACTION } from 'actions/user/patchUser';
import { patchYearlyUserInfo } from 'actions/user/patchYearlyUserInfo';
import { sendRenewalSelectionReviewEmail } from 'actions/user/sendRenewalSelectionReviewEmail';
import {
    EnrollmentStatuses,
    IneligibleToKeepReasons,
    PlanStateIds,
    PlanTypeIds,
    RenewalDecisions,
    TaskItems,
} from 'api/generated/enums';
import { ISelectedPlan, IYearlyUserInfoDto } from 'api/generated/models';
import Button from 'components/Button';
import CalendlyModalButton from 'components/calendly/CalendlyModalButton';
import ConditionalTooltip from 'components/ConditionalTooltip';
import ScheduleAdvisementModalLink from 'components/helpComponents/ScheduleAdvisementModalLink';
import InformationIconTooltip from 'components/InformationIconTooltip';
import MajorMedicalBenefitCost from 'components/majorMedicalBenefitCostBreakdownModal/MajorMedicalBenefitCost';
import { hideDeductibleBenefitText } from 'components/majorMedicalBenefitCostBreakdownModal/majorMedicalBenefitCostBreakdown';
import ScrollGradientIndicator from 'components/ScrollGradientIndicator';
import Skeleton from 'components/Skeleton';
import { push } from 'connected-react-router';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import cloneDeep from 'lodash/cloneDeep';
import some from 'lodash/some';
import BenefitSectionHeader from 'pages/benefits/BenefitSectionHeader';
import KeepOrChangeBenefitCard from 'pages/dashboard/taskLists/member/tasks/keepOrChange/KeepOrChangeBenefitCard';
import { getShouldHideCosts } from 'pages/dashboard/taskLists/member/tasks/keepOrChange/keepOrChangeSelectors';
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { SHOP_PATH } from 'routers/routes';
import { hasApiActivity } from 'selectors/activity';
import { hasContents, hasValue, RecursivePartial } from 'utilities';
import { formatCurrency } from 'utilities/format';
import { API_DATE_FORMAT, getEndDateFromYear } from 'utilities/moment';

declare global {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Beacon: any;
    }
}

export const SCROLL_BUFFER = 5;
const EstimatedAncillaryCosts = ({
    estimatedAncillaryCosts,
}: {
    estimatedAncillaryCosts: number;
}) => (
    <Typography color="primary" justifyContent="center" noWrap>
        Estimated Individual Ancillary Cost{' '}
        <Typography color="secondary" component="span">
            {formatCurrency(estimatedAncillaryCosts)}
        </Typography>{' '}
        <Typography component="span" variant="caption">
            per month
        </Typography>
        <InformationIconTooltip
            className="ml-1"
            title="The sum of the estimated costs for each Individual Ancillary Benefit"
        />
    </Typography>
);

const KeepButtonWrapper = ({
    children,
    hasIneligibleOrDiscontinuedPlans,
    ineligibleMessage,
}: {
    children: ReactElement;
    hasIneligibleOrDiscontinuedPlans: boolean;
    ineligibleMessage: string;
}) => (
    <ConditionalTooltip isDisabled={!hasIneligibleOrDiscontinuedPlans} title={ineligibleMessage}>
        {children}
    </ConditionalTooltip>
);
const HELPSCOUT_BEACON_ORGANIZATION_NAME_FIELD_ID = 17603;
const KeepOrChangeBenefitsModal = ({ onHide }: { onHide: () => void }) => {
    const dispatch = useThunkDispatch();
    const { teamId, teamName } = useTeamProps();
    const { isCurrent, user, userId, yearlyUserInfo, year } = useUserProps();
    const scrollContent = useRef<HTMLDivElement | null>(null);
    const [shouldScroll, setShouldScroll] = useState(false);
    const {
        ancillaries,
        ancillaryBenefitCost,
        comparedSelectedPlans,
        effectiveAncillarySelectedPlans,
        effectiveMajorMedicalSelectedPlans,
        householdMembers,
        isLoading,
        isOnParentOrSpousePlan,
        isSaving,
        majorMedicalBenefitCost,
        selectedPlansToDelete,
        shouldHideCosts,
    } = useSelector((state: AppStore) => ({
        ancillaries: state.ancillaryBenefits,
        ancillaryBenefitCost: state.selectedPlansForRenewal.estimatedEffectiveAncillaryCost,
        comparedSelectedPlans:
            state.selectedPlansForRenewal?.comparedMarketplaceAndOffExchangeSelectedPlans ?? [],
        effectiveAncillarySelectedPlans:
            state.selectedPlansForRenewal?.effectiveAncillarySelectedPlans ?? [],
        effectiveMajorMedicalSelectedPlans:
            state.selectedPlansForRenewal?.effectiveMajorMedicalSelectedPlans ?? [],
        householdMembers: state.householdMembers,
        isLoading: hasApiActivity(
            state,
            GET_HOUSEHOLD_MEMBERS_ACTION,
            GET_SELECTED_PLANS_FOR_RENEWAL_ACTION
        ),
        isOnParentOrSpousePlan: state.selectedPlansForRenewal.allPlansParentOrSpouse,
        isSaving: hasApiActivity(
            state,
            ADD_CUSTOM_SELECTED_PLANS_ACTION,
            PATCH_USER_ACTION,
            SAVE_COMPLETED_USER_TASK_ACTION
        ),
        majorMedicalBenefitCost: state.selectedPlansForRenewal.majorMedicalBenefitCost,
        selectedPlansToDelete: state.selectedPlansForRenewal.selectedPlansToDelete ?? [],
        shouldHideCosts: getShouldHideCosts(state),
    }));

    const shouldHideDeductibleBenefitText =
        hideDeductibleBenefitText(comparedSelectedPlans) ||
        hideDeductibleBenefitText(effectiveMajorMedicalSelectedPlans);

    const hasCustomStateBasedExchange = [
        ...comparedSelectedPlans,
        ...effectiveMajorMedicalSelectedPlans,
    ].some((plan) => plan.planTypeId === PlanTypeIds.CustomStateBasedExchange);
    const [isKeeping, setIsKeeping] = useState(false);

    useEffect(() => {
        dispatch(getLatestYearForRates());
        dispatch(getTeamOperationsInfo(teamId, year));
    }, [dispatch, teamId, year]);

    const { hasIneligiblePlan, ineligibleMessage, planIncludesUnclaimedMembers } = useMemo(() => {
        const advisementRequiredIneligibleReasons = [
            IneligibleToKeepReasons.HouseholdEligibilityChanges,
            IneligibleToKeepReasons.IncludesUnclaimedMembers,
        ];
        const ineligiblePlans = comparedSelectedPlans.filter((x) =>
            hasValue(x.ineligibleToKeepReason)
        );
        const hasIneligiblePlanInternal = ineligiblePlans.length > 0;
        const useAdvisementMessage =
            hasIneligiblePlanInternal &&
            ineligiblePlans.some((x) =>
                advisementRequiredIneligibleReasons.contains(x.ineligibleToKeepReason)
            );
        return {
            hasIneligiblePlan: hasIneligiblePlanInternal,
            ineligibleMessage: useAdvisementMessage
                ? 'You may not be able to keep all of your current plans. ' +
                  'Please schedule an advisement with one of our team members, ' +
                  'or reach out to us with any questions using the links below.'
                : 'You are ineligible for one or more plans due to household changes or plans leaving the market and are not able to keep all of your current plans.',
            planIncludesUnclaimedMembers: ineligiblePlans.some(
                (x) => x.ineligibleToKeepReason === IneligibleToKeepReasons.IncludesUnclaimedMembers
            ),
        };
    }, [comparedSelectedPlans]);
    const allSelectedMajorMedicalPlans = useMemo(
        () => [...comparedSelectedPlans, ...effectiveMajorMedicalSelectedPlans],
        [comparedSelectedPlans, effectiveMajorMedicalSelectedPlans]
    );

    const createKeepingPlans = useCallback(async () => {
        const newMarketplacePlans = cloneDeep(
            comparedSelectedPlans.filter((x) => x.planTypeId === PlanTypeIds.Marketplace)
        );
        const existingMarketplacePlans = cloneDeep(
            effectiveMajorMedicalSelectedPlans.filter(
                (x) => x.planTypeId === PlanTypeIds.Marketplace
            )
        );
        const termMedicalPlans = cloneDeep(
            allSelectedMajorMedicalPlans.filter((x) => x.planTypeId === PlanTypeIds.TermMedical)
        );
        const offExchangePlans = cloneDeep(
            allSelectedMajorMedicalPlans.filter((x) => x.planTypeId === PlanTypeIds.OffExchange)
        );
        [...newMarketplacePlans, ...existingMarketplacePlans, ...offExchangePlans].forEach((x) => {
            x.year = year as number;
            x.coverageStartDate = `1/1/${year}`;
            x.coverageEndDate = `12/31/${year}`;
        });
        const spouseAndParentPlans = cloneDeep(
            effectiveMajorMedicalSelectedPlans.filter(
                (x) =>
                    x.planTypeId === PlanTypeIds.SpouseEmployer ||
                    x.planTypeId === PlanTypeIds.ParentEmployer
            )
        );

        spouseAndParentPlans.forEach((x) => {
            x.year = year as number;
            x.coverageStartDate = user?.activeDate;
            x.coverageEndDate = getEndDateFromYear(user?.activeDate?.getYear());
            delete x.comment;
        });
        existingMarketplacePlans.forEach((x) => {
            delete x.planPremium;
            delete x.planPremiumWithCredits;
        });
        termMedicalPlans.forEach((x) => {
            x.planPremium = 0;
            x.planPremiumWithCredits = 0;
            x.year = x.coverageEndDate?.getYear() ?? (year as number);
            const diffInMilliseconds = x.coverageEndDate
                ?.toMomentDate()
                .diff(x.coverageStartDate?.toMomentDate(), 'days');
            const newCoverageEndDate = x.coverageEndDate
                ?.toMomentDate()
                .add(diffInMilliseconds, 'days');
            x.coverageStartDate = x.coverageEndDate;
            x.coverageEndDate = newCoverageEndDate?.format(API_DATE_FORMAT);
        });
        const plansToAdd = [
            ...termMedicalPlans,
            ...newMarketplacePlans,
            ...existingMarketplacePlans,
            ...spouseAndParentPlans,
            ...offExchangePlans,
        ]
            .map((x: Partial<ISelectedPlan>) => {
                x.planStateId = PlanStateIds.Selected;
                x.householdMembersCovered = x.householdMembersCovered?.filter((hmc) =>
                    householdMembers.some((hm) => hm.householdMemberId === hmc)
                );
                delete x.applicationDate;
                delete x.applicationLink;
                delete x.binderStatus;
                delete x.isAddedToPaymentTracking;
                delete x.isAddedToPayrollReport;
                delete x.isPassiveRenewal;
                delete x.memberId;
                delete x.payTypeId;
                delete x.selectedPlanId;
                return x;
            })
            .filter((x) => x.isPrimaryCovered || hasContents(x.householdMembersCovered));
        if (plansToAdd.length) {
            await dispatch(
                addCustomSelectedPlans(plansToAdd, 'Successfully marked plans as keeping!')
            );
        }
    }, [
        allSelectedMajorMedicalPlans,
        comparedSelectedPlans,
        dispatch,
        effectiveMajorMedicalSelectedPlans,
        householdMembers,
        user?.activeDate,
        year,
    ]);
    const closeAndCompleteTask = useCallback(async () => {
        await dispatch(saveCompletedUserTask(TaskItems.KeepOrChangeBenefits_Renewing));
        onHide();
    }, [dispatch, onHide]);
    const removePreviousDecisionPlans = useCallback(async () => {
        for (const plan of selectedPlansToDelete) {
            await dispatch(deleteSelectedPlan(plan.selectedPlanId));
        }
    }, [dispatch, selectedPlansToDelete]);
    const saveChangeDecision = useCallback(
        async (additionalPatchData?: RecursivePartial<IYearlyUserInfoDto>) => {
            await removePreviousDecisionPlans();
            await dispatch(
                patchYearlyUserInfo(
                    userId,
                    year,
                    {
                        renewalDecision: RenewalDecisions.Change,
                        ...additionalPatchData,
                    },
                    isCurrent
                )
            );
            await dispatch(getUserProfile(userId, isCurrent));
        },
        [dispatch, isCurrent, removePreviousDecisionPlans, userId, year]
    );
    const onChangingClick = useCallback(async () => {
        saveChangeDecision({
            enrollmentStatus: { value: EnrollmentStatuses.BenefitsSelection },
        });

        !isOnParentOrSpousePlan || (isOnParentOrSpousePlan && ancillaries.length > 0)
            ? dispatch(push(SHOP_PATH))
            : await dispatch(saveCompletedUserTask(TaskItems.KeepOrChangeBenefits_Renewing));
    }, [ancillaries, dispatch, isOnParentOrSpousePlan, saveChangeDecision]);
    const setUserAdvisementScheduled = useCallback(async () => {
        await saveChangeDecision({
            enrollmentStatus: { value: EnrollmentStatuses.AdvisementScheduled },
        });
        await dispatch(saveCompletedUserTask(TaskItems.KeepOrChangeBenefits_Renewing));
    }, [dispatch, saveChangeDecision]);
    const setUserAdvisementScheduledForUnclaimedMembers = useCallback(async () => {
        await dispatch(
            patchYearlyUserInfo(
                userId,
                year,
                {
                    enrollmentStatus: {
                        value: EnrollmentStatuses.AdvisementScheduled,
                    },
                },
                isCurrent
            )
        );
        closeAndCompleteTask();
    }, [closeAndCompleteTask, dispatch, isCurrent, userId, year]);
    const onKeepingClick = useCallback(async () => {
        setIsKeeping(true);
        await removePreviousDecisionPlans();
        if (!hasCustomStateBasedExchange) {
            await createKeepingPlans();
        }
        await dispatch(
            patchYearlyUserInfo(
                userId,
                year,
                {
                    enrollmentStatus: {
                        value: EnrollmentStatuses.PendingApplication,
                    },
                    renewalDecision: RenewalDecisions.Keep,
                },
                isCurrent
            )
        );
        await dispatch(sendRenewalSelectionReviewEmail(userId, year));
        await dispatch(getSelectedPlansForRenewal(userId, year as number));
        closeAndCompleteTask();
    }, [
        closeAndCompleteTask,
        createKeepingPlans,
        dispatch,
        isCurrent,
        hasCustomStateBasedExchange,
        removePreviousDecisionPlans,
        userId,
        year,
    ]);
    const openBeacon = useCallback(() => {
        window.Beacon('navigate', '/ask/message/');
        window.Beacon('prefill', {
            company: teamName,
            email: user?.email,
            fields: [{ id: HELPSCOUT_BEACON_ORGANIZATION_NAME_FIELD_ID, value: teamName }],
            name: `${user?.firstName} ${user?.lastName}`,
            subject: 'Need help with my renewal decision',
        });
        window.Beacon('open');
    }, [teamName, user?.email, user?.firstName, user?.lastName]);

    const ifAnyChanges = useMemo(
        () => some(comparedSelectedPlans, (x) => hasContents(x.comparisons)),
        [comparedSelectedPlans]
    );
    const showKeepButton = useMemo(
        () => RenewalDecisions.Keep !== yearlyUserInfo?.renewalDecision,
        [yearlyUserInfo]
    );
    const unclaimedHouseholdMemberContent = (
        <React.Fragment>
            {yearlyUserInfo?.enrollmentStatus?.value === EnrollmentStatuses.AdvisementScheduled ? (
                'Your advisement has been scheduled!'
            ) : (
                <CalendlyModalButton
                    buttonLabel="Schedule an Advisement"
                    className="mr-2"
                    data-cy="schedule-change-benefits"
                    disabled={isLoading}
                    onAdvisementScheduled={setUserAdvisementScheduledForUnclaimedMembers}
                    variant="contained"
                ></CalendlyModalButton>
            )}
        </React.Fragment>
    );
    const changeButton = (
        <React.Fragment>
            {!hasCustomStateBasedExchange && yearlyUserInfo?.allowShopping ? (
                <Button
                    className="mr-2"
                    data-cy="change-benefits"
                    disabled={isLoading}
                    isLoading={!isKeeping && isSaving}
                    onClick={onChangingClick}
                    variant={showKeepButton ? 'outlined' : 'contained'}
                >
                    Change
                </Button>
            ) : (
                <CalendlyModalButton
                    buttonLabel="Change"
                    className="mr-2"
                    data-cy="change-benefits"
                    disabled={isLoading}
                    onAdvisementScheduled={setUserAdvisementScheduled}
                    variant={showKeepButton ? 'outlined' : 'contained'}
                ></CalendlyModalButton>
            )}
        </React.Fragment>
    );
    const keepButton = (
        <KeepButtonWrapper
            hasIneligibleOrDiscontinuedPlans={hasIneligiblePlan}
            ineligibleMessage={ineligibleMessage}
        >
            <Button
                ButtonClassName="px-5"
                data-cy="keep-benefits"
                disabled={hasIneligiblePlan || isLoading}
                isLoading={isKeeping && isSaving}
                onClick={onKeepingClick}
                type="submit"
                variant="contained"
            >
                Keep
            </Button>
        </KeepButtonWrapper>
    );
    const keepOrChangeButtonsContent = (
        <React.Fragment>
            {changeButton}
            {showKeepButton && keepButton}
        </React.Fragment>
    );

    const buttonContent = planIncludesUnclaimedMembers
        ? unclaimedHouseholdMemberContent
        : keepOrChangeButtonsContent;

    useEffect(() => {
        if (hasValue(scrollContent.current)) {
            const { scrollHeight, clientHeight } = scrollContent.current;

            setShouldScroll(scrollHeight > clientHeight + SCROLL_BUFFER);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollContent.current]);

    return (
        <Dialog fullWidth maxWidth="lg" onClose={onHide} open style={{ overflow: 'hidden' }}>
            <DialogTitle>Keep or Change Benefits</DialogTitle>
            <Divider />
            <DialogContent id="keepOrChangeScrollContainer" ref={scrollContent}>
                <Skeleton count={2} height="119px" isEnabled={isLoading} sx={{ mb: 2 }}>
                    <ScrollGradientIndicator
                        includeTopOffset={false}
                        scrollElementId="keepOrChangeScrollContainer"
                        shouldScroll={shouldScroll}
                    >
                        <Stack>
                            <p>
                                Do you want to keep your benefits?{' '}
                                {ifAnyChanges && `Here is how they will change for ${year}:`}
                            </p>
                            {allSelectedMajorMedicalPlans.length > 0 && (
                                <React.Fragment>
                                    <BenefitSectionHeader>
                                        Major Medical Benefits
                                    </BenefitSectionHeader>
                                    {allSelectedMajorMedicalPlans.map((selectedPlan, index) => (
                                        <KeepOrChangeBenefitCard
                                            key={selectedPlan.selectedPlanId ?? index}
                                            selectedPlan={selectedPlan}
                                        />
                                    ))}
                                    {!shouldHideCosts && !hasIneligiblePlan && (
                                        <Stack alignItems="center">
                                            <MajorMedicalBenefitCost
                                                costText="Estimated Major Medical Cost"
                                                majorMedicalBenefitCost={majorMedicalBenefitCost}
                                                shouldHideDeductibleBenefitText={
                                                    shouldHideDeductibleBenefitText
                                                }
                                            />
                                        </Stack>
                                    )}
                                </React.Fragment>
                            )}
                            {effectiveAncillarySelectedPlans.length > 0 && (
                                <React.Fragment>
                                    <BenefitSectionHeader>
                                        Individual Ancillary Benefits
                                    </BenefitSectionHeader>
                                    {effectiveAncillarySelectedPlans.map((selectedPlan, index) => (
                                        <KeepOrChangeBenefitCard
                                            isAncillary
                                            key={selectedPlan.selectedPlanId ?? index}
                                            selectedPlan={selectedPlan}
                                        />
                                    ))}
                                    {!shouldHideCosts && (
                                        <Stack alignItems="center">
                                            <EstimatedAncillaryCosts
                                                estimatedAncillaryCosts={ancillaryBenefitCost}
                                            />
                                        </Stack>
                                    )}
                                </React.Fragment>
                            )}
                            <Divider />
                            <Grid container justifyContent="center" marginTop={2}>
                                {buttonContent}
                                <Grid
                                    alignItems="center"
                                    container
                                    direction="column"
                                    item
                                    marginTop={2}
                                >
                                    <Typography variant="h5">
                                        Need help making a decision?
                                    </Typography>
                                    <Typography fontWeight="normal" variant="h5">
                                        You can <ScheduleAdvisementModalLink /> or{' '}
                                        <span
                                            className="text-primary clickable"
                                            onClick={openBeacon}
                                        >
                                            email
                                        </span>{' '}
                                        us.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Stack>
                    </ScrollGradientIndicator>
                </Skeleton>
            </DialogContent>
        </Dialog>
    );
};

export default hot(module)(KeepOrChangeBenefitsModal);
