import { Dialog, DialogContent, DialogTitle, Divider, Grid, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { GET_MARKETPLACE_PLAN_RATES_FOR_PLANS_ACTION } from 'actions/marketplacePlan/getMarketplacePlanRatesForPlans';
import {
    GET_STATE_BASED_EXCHANGE_STATES_SHOULD_FORCE_ADVISEMENT_ACTION,
    getStateBasedExchangeStatesShouldForceAdvisement,
} from 'actions/marketplacePlan/getStateBasedExchangeStatesShouldForceAdvisement';
import { GET_MEDISHARE_PLANS_FOR_USER_ACTION } from 'actions/medishare/getMediSharePlansAndRatesForUser';
import {
    APPLY_PATHWAY_DECISION_ACTION,
    applyPathwayDecision,
} from 'actions/pathwayBlueprint/applyPathwayDecision';
import { deleteSelectedPlan } from 'actions/selectedPlan/deleteSelectedPlan';
import { GET_SELECTED_PLANS_ACTION, getSelectedPlans } from 'actions/selectedPlan/getSelectedPlans';
import { getCompletedUserTasks } from 'actions/task/getCompletedUserTasks';
import { getUserProfile } from 'actions/user/getUserProfile';
import { patchYearlyUserInfo } from 'actions/user/patchYearlyUserInfo';
import {
    EnrollmentStatuses,
    PathwayDecisions,
    PathwayTypes,
    PlanStateIds,
    PlanTypeIds,
    RenewalDecisions,
    UserStatus,
} from 'api/generated/enums';
import { IPathwayDecisionDto, ISelectedPlan, YearlyUserInfoDto } from 'api/generated/models';
import Button from 'components/Button';
import CalendlyModalButton from 'components/calendly/CalendlyModalButton';
import HelpContact from 'components/helpComponents/HelpContact';
import ScheduleAdvisementModalLink from 'components/helpComponents/ScheduleAdvisementModalLink';
import PathwayTitle from 'components/PathwayTitle';
import ScrollGradientIndicator from 'components/ScrollGradientIndicator';
import Skeleton from 'components/Skeleton';
import { push } from 'connected-react-router';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { BenefitSelectionContext } from 'pages/dashboard/medicalBenefitSelection/BenefitSelectionContent';
import HealthcareSharingPathwayPlanDetails from 'pages/dashboard/medicalBenefitSelection/HealthcareSharingPathwayPlanDetails';
import OnOrOffExchangePathwayPlanDetails, {
    ContributionStrategy,
} from 'pages/dashboard/medicalBenefitSelection/OnOrOffExchangePathwayPlanDetails';
import WageUpRequestPlanDetails from 'pages/dashboard/medicalBenefitSelection/WageUpRequestPlanDetails';
import { SCROLL_BUFFER } from 'pages/dashboard/taskLists/member/tasks/keepOrChange/KeepOrChangeBenefitsModal';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { SHOP_PATH } from 'routers/routes';
import { hasApiActivity } from 'selectors/activity';
import { hasValue } from 'utilities';
import { getIsStateBasedExchange } from 'utilities/selectedPlan';

type IBenefitSelectionModalProps = {
    onClose: () => void;
};

const getModalContent = (pathwayType: PathwayTypes) => {
    let content;
    let overrideDisplayButtons = false;
    switch (pathwayType) {
        case PathwayTypes.MarketplaceCappedEmployeeCost:
        case PathwayTypes.MarketplaceCappedEmployerCost:
            content = (
                <OnOrOffExchangePathwayPlanDetails
                    contributionStrategy={ContributionStrategy.WageUp}
                />
            );
            break;
        case PathwayTypes.IchraAgeBanded:
        case PathwayTypes.IchraCappedEmployeeCost:
        case PathwayTypes.IchraCappedEmployerCost:
            content = (
                <OnOrOffExchangePathwayPlanDetails
                    contributionStrategy={ContributionStrategy.Reimbursement}
                />
            );
            break;
        case PathwayTypes.HealthcareSharingCappedEmployeeCost:
        case PathwayTypes.HealthcareSharingCappedEmployerCost:
            content = <HealthcareSharingPathwayPlanDetails />;
            overrideDisplayButtons = true;
            break;
        case PathwayTypes.Spouse:
            content = (
                <WageUpRequestPlanDetails planType={PlanTypeIds.SpouseEmployer} relation="spouse" />
            );
            overrideDisplayButtons = true;
            break;
        case PathwayTypes.Parent:
            content = (
                <WageUpRequestPlanDetails planType={PlanTypeIds.ParentEmployer} relation="parent" />
            );
            overrideDisplayButtons = true;
            break;
        default:
            content = <React.Fragment />;
    }
    return { content, overrideDisplayButtons };
};

const checkIfPathwaySelectionMade = (yearlyUserInfo: YearlyUserInfoDto | undefined) =>
    (yearlyUserInfo?.acceptedPathway ||
        yearlyUserInfo?.renewalDecision === RenewalDecisions.AcceptedNewPathway) &&
    yearlyUserInfo?.enrollmentStatus?.value === EnrollmentStatuses.PendingApplication;

const BenefitSelectionModal = ({ onClose }: IBenefitSelectionModalProps) => {
    const dispatch = useThunkDispatch();
    const { isCurrent, user, userId, yearlyUserInfo } = useUserProps();
    const { calendlyAdvisementLink, sbeEnrollmentCalendlyLink } = useTeamProps();
    const { isAnyMemberCovered } = useContext(BenefitSelectionContext);
    const scrollContent = useRef<HTMLDivElement | null>(null);
    const {
        benefitPlan,
        hasSbePlan,
        isAboveApprovalCap,
        isLoading,
        pathwayDataForUser,
        requestedWageUp,
        selectedPlans,
        selectedPlansToDelete,
        shouldForceAdvisementForSbe,
        useRestrategizedRenewalFlow,
    } = useSelector((state: AppStore) => ({
        benefitPlan: state.benefitSelectionState.plan,
        hasSbePlan: state.marketplacePlans.some((x) => getIsStateBasedExchange(x, true)),
        isAboveApprovalCap: state.benefitSelectionState.isAboveApprovalCap,
        isLoading: hasApiActivity(
            state,
            APPLY_PATHWAY_DECISION_ACTION,
            GET_SELECTED_PLANS_ACTION,
            GET_STATE_BASED_EXCHANGE_STATES_SHOULD_FORCE_ADVISEMENT_ACTION,
            GET_MEDISHARE_PLANS_FOR_USER_ACTION,
            GET_MARKETPLACE_PLAN_RATES_FOR_PLANS_ACTION
        ),
        pathwayDataForUser: state.pathwayDataForUser,
        requestedWageUp: state.benefitSelectionState.requestedWageUp,
        selectedPlans: state.selectedPlans,
        selectedPlansToDelete: state.selectedPlans.filter(
            (x) =>
                [PlanStateIds.Selected, PlanStateIds.Submitted].includes(x.planStateId) &&
                x.selectedPlanId
        ),
        shouldForceAdvisementForSbe: state.marketplaceConfig.shouldForceAdvisementForSbe,
        useRestrategizedRenewalFlow: state.pathwayDataForUser.useRestrategizedRenewalFlow,
    }));

    const applyDecisionCompleteTaskAndClose = useCallback(async () => {
        const requestingWageUp = hasValue(requestedWageUp);
        const decision = requestingWageUp
            ? PathwayDecisions.WageUpRequested
            : PathwayDecisions.Accepted;
        const requestedWageUpForDecision = requestingWageUp ? Number(requestedWageUp) : undefined;
        await dispatch(
            applyPathwayDecision({
                decision,
                userId,
                pathwayId: pathwayDataForUser.id,
                planData: benefitPlan,
                requestedWageUp: requestedWageUpForDecision,
            } as IPathwayDecisionDto)
        );
        dispatch(getCompletedUserTasks());
        onClose();
    }, [benefitPlan, dispatch, onClose, pathwayDataForUser.id, requestedWageUp, userId]);
    const removePreviousDecisionPlans = useCallback(async () => {
        for (const plan of selectedPlansToDelete) {
            await dispatch(deleteSelectedPlan(plan.selectedPlanId));
        }
    }, [dispatch, selectedPlansToDelete]);
    const onSbeAdvisementScheduled = useCallback(async () => {
        await removePreviousDecisionPlans();
        await dispatch(
            patchYearlyUserInfo(
                userId,
                yearlyUserInfo?.year,
                {
                    enrollmentStatus: { value: EnrollmentStatuses.AdvisementScheduled },
                },
                isCurrent
            )
        );
        await dispatch(getUserProfile(userId, isCurrent));
        applyDecisionCompleteTaskAndClose();
    }, [
        applyDecisionCompleteTaskAndClose,
        dispatch,
        isCurrent,
        removePreviousDecisionPlans,
        userId,
        yearlyUserInfo?.year,
    ]);
    const hasMadePathwaySelection = checkIfPathwaySelectionMade(yearlyUserInfo);
    let shouldScroll = false;

    if (hasValue(scrollContent.current)) {
        const { scrollHeight, clientHeight } = scrollContent.current;

        shouldScroll = scrollHeight > clientHeight + SCROLL_BUFFER;
    }

    const deletePlan = useCallback(
        (plan: ISelectedPlan) => {
            if (plan.planStateId === PlanStateIds.Selected && plan.year === yearlyUserInfo?.year) {
                dispatch(deleteSelectedPlan(plan.selectedPlanId, 'Successfully removed plan!'));
            }
        },
        [dispatch, yearlyUserInfo?.year]
    );
    const onSkipBenefit = useCallback(
        async ({ advisementScheduled = false }) => {
            if (hasMadePathwaySelection) {
                selectedPlans.forEach((x) => deletePlan(x));
            }
            await dispatch(
                applyPathwayDecision({
                    advisementScheduled,
                    userId,
                    decision: PathwayDecisions.Skip,
                    pathwayId: pathwayDataForUser.id,
                })
            );
            if (yearlyUserInfo?.allowShopping) {
                dispatch(push(SHOP_PATH));
            }
        },
        [
            deletePlan,
            dispatch,
            hasMadePathwaySelection,
            pathwayDataForUser.id,
            selectedPlans,
            userId,
            yearlyUserInfo?.allowShopping,
        ]
    );
    const onAdvisementScheduled = useCallback(() => {
        onSkipBenefit({ advisementScheduled: true });
    }, [onSkipBenefit]);
    const { content: modalContent, overrideDisplayButtons } = getModalContent(
        pathwayDataForUser.pathwayType
    );
    const getSkipText = () => {
        const instead = hasMadePathwaySelection ? ' instead' : '';
        return yearlyUserInfo?.allowShopping
            ? `See all plans${instead}`
            : `Schedule an advisement${instead}`;
    };
    const getSkipContent = () => {
        let content = (
            <Button
                color="secondary"
                data-cy="skip"
                disabled={isLoading}
                isLoading={isLoading}
                onClick={onSkipBenefit}
                variant="text"
            >
                {getSkipText()}
            </Button>
        );
        if (!yearlyUserInfo?.allowShopping) {
            content = (
                <ScheduleAdvisementModalLink
                    dataCy="skip"
                    onAdvisementScheduled={onAdvisementScheduled}
                    textOverride={getSkipText()}
                />
            );
        }

        return content;
    };
    const getSelectContent = () => {
        let content;
        if (hasMadePathwaySelection) {
            content = (
                <Typography variant="h4">You have selected your recommended benefits!</Typography>
            );
        } else if (
            hasSbePlan &&
            shouldForceAdvisementForSbe &&
            (user?.status === UserStatus.Launching ||
                (user?.status === UserStatus.Renewing && useRestrategizedRenewalFlow))
        ) {
            const calendlyLink = hasValue(sbeEnrollmentCalendlyLink)
                ? sbeEnrollmentCalendlyLink
                : calendlyAdvisementLink;
            content = (
                <CalendlyModalButton
                    buttonLabel="Select"
                    calendlyLinkOverride={calendlyLink}
                    data-cy="select-benefit"
                    disabled={isLoading}
                    onAdvisementScheduled={onSbeAdvisementScheduled}
                    variant="outlined"
                ></CalendlyModalButton>
            );
        } else {
            content = (
                <Button
                    data-cy="select-benefit"
                    disabled={isLoading}
                    isLoading={isLoading}
                    onClick={applyDecisionCompleteTaskAndClose}
                    variant="contained"
                >
                    Select
                </Button>
            );
        }

        return content;
    };

    useEffect(() => {
        dispatch(getSelectedPlans(userId));
        dispatch(getStateBasedExchangeStatesShouldForceAdvisement(userId));
    }, [dispatch, userId]);

    return (
        <Dialog fullWidth maxWidth="lg" onClose={onClose} open>
            <DialogTitle>
                <PathwayTitle pathwayType={pathwayDataForUser.pathwayType} />
            </DialogTitle>
            <Divider />
            <DialogContent id="benefitSelectionScrollContainer" ref={scrollContent}>
                <Skeleton count={2} height="119px" isEnabled={isLoading} sx={{ mb: 2 }}>
                    <ScrollGradientIndicator
                        includeTopOffset={false}
                        scrollElementId="benefitSelectionScrollContainer"
                        shouldScroll={shouldScroll}
                    >
                        <Stack>
                            {modalContent}
                            <Divider />
                            <Grid container justifyContent="center" marginTop={2}>
                                {isAboveApprovalCap ? (
                                    <React.Fragment>
                                        <Button
                                            className="mr-2"
                                            disabled={isLoading}
                                            onClick={onClose}
                                            variant="outlined"
                                        >
                                            Close
                                        </Button>
                                        <Button
                                            isLoading={isLoading}
                                            onClick={applyDecisionCompleteTaskAndClose}
                                            variant="contained"
                                        >
                                            Submit Request
                                        </Button>
                                    </React.Fragment>
                                ) : (
                                    (isAnyMemberCovered || overrideDisplayButtons) && (
                                        <Grid
                                            container
                                            direction="row"
                                            gap={2}
                                            item
                                            justifyContent="center"
                                        >
                                            {getSkipContent()}
                                            {getSelectContent()}
                                        </Grid>
                                    )
                                )}
                                <Grid marginTop={4}>
                                    <HelpContact />
                                </Grid>
                            </Grid>
                        </Stack>
                    </ScrollGradientIndicator>
                </Skeleton>
            </DialogContent>
        </Dialog>
    );
};

export default hot(module)(BenefitSelectionModal);
