import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { EnrollmentStatuses, UserInfoStatus } from 'api/generated/enums';
import { IUserProfile } from 'api/generated/models';
import { ViewEnrollmentVerification, ViewUsers } from 'api/generated/permissions';
import { ROLE_IDS } from 'api/generated/roleIds';
import AdminChip from 'components/chip/AdminChip';
import VipChip from 'components/chip/VipChip';
import { ISetSelected } from 'components/headerDropdown/DropdownMenuItems';
import HeaderDropdown from 'components/headerDropdown/HeaderDropdown';
import { ISetSorted, ISorted } from 'components/headerDropdown/SortDropdownMenuItems';
import InvalidEmailPill from 'components/InvalidEmailPill';
import NameTableCell from 'components/NameTableCell';
import NeedsFollowUpPill from 'components/NeedsFollowUpPill';
import ProfileActionButtons from 'components/profileActionButtons/ProfileActionButtons';
import UnderlinedContentWithTooltip from 'components/UnderlinedContentWithTooltip';
import UserInfoTableCell from 'components/UserInfoTableCell';
import TeamManagementContext from 'contexts/TeamManagementContext';
import startCase from 'lodash/startCase';
import React, { useContext } from 'react';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { Cell, HeaderProps } from 'react-table';
import { AppStore } from 'reducers/appReducer';
import { PEOPLE_PROFILE_PATH, TEAMS_PEOPLE_PROFILE_PATH } from 'routers/routes';
import { hasSomePermissions } from 'selectors/index';
import { isAdvisorSelector, isRhSelector, isTeamAdminSelector } from 'selectors/role';
import styled from 'styled-components';
import { getEnumNames, hasValue } from 'utilities';
import { formatDateForDisplay } from 'utilities/format';
import { CustomColumnCell, CustomColumnHeader } from 'utilities/reactTable';

export type IPeopleTableEnrollmentStatusColumnFields = {
    selectedEnrollmentStatuses: string[];
    setSelectedEnrollmentStatuses: ISetSelected;
    setSorted: ISetSorted;
    sorted: ISorted[];
};
export const ENROLLMENT_STATUS_OPTIONS = [...getEnumNames(EnrollmentStatuses, startCase), 'None'];
export const ENROLLMENT_STATUS_PARAM = 'Enrollment Status';
export const EnrollmentStatusHeader = ({
    column: { id, filterValue, setFilter },
}: HeaderProps<IUserProfile>) => (
    <HeaderDropdown
        columnId={id}
        id="enrollment-status-filter-dropdown"
        infoTooltip="This status indicates where in the enrollment process this member is at for this year."
        options={ENROLLMENT_STATUS_OPTIONS}
        selected={filterValue}
        setSelected={setFilter}
        title={ENROLLMENT_STATUS_PARAM}
    />
);

export const INFO_PARAM = 'Info';
export const InfoHeader = ({
    column: { id, filterValue, setFilter, userInfoStatusPrettyNames },
}: CustomColumnHeader<IUserProfile, { userInfoStatusPrettyNames: string[] }>) => (
    <HeaderDropdown
        columnId={id}
        id="info-filter-dropdown"
        infoTooltip="The level of information we have for the member in our system."
        options={userInfoStatusPrettyNames}
        selected={filterValue}
        setSelected={setFilter}
        title={INFO_PARAM}
    />
);

const PillContainer = styled(Row)`
    gap: 4px 0px;
`;

export const NameCell = ({
    row: { original: profile },
}: CustomColumnCell<IUserProfile, string>) => {
    const { isTeamManagementPage } = useContext(TeamManagementContext);
    const {
        canViewEnrollmentVerification,
        canViewProfile,
        canViewVip,
        teamAdmins,
        teamPayrollAdmins,
    } = useSelector((state: AppStore) => ({
        canViewEnrollmentVerification: hasSomePermissions(state, ViewEnrollmentVerification),
        canViewProfile: hasSomePermissions(state, ViewUsers),
        canViewVip: isRhSelector(state) || isAdvisorSelector(state) || isTeamAdminSelector(state),
        teamAdmins: state.teamUserRoles.filter((u) =>
            u.roleIds?.some((x) => x === ROLE_IDS.TEAM_ADMIN)
        ),
        teamPayrollAdmins: state.teamUserRoles.filter((u) =>
            u.roleIds?.some((x) => x === ROLE_IDS.TEAM_PAYROLL_ADMIN)
        ),
    }));

    const { isVip, teamId, userId } = profile.user ?? {};
    const profilePath = isTeamManagementPage
        ? generatePath(TEAMS_PEOPLE_PROFILE_PATH, { teamId, userId })
        : generatePath(PEOPLE_PROFILE_PATH, { userId });

    return (
        <React.Fragment>
            {canViewProfile ? (
                <Link data-cy="person-name" to={profilePath}>
                    <NameTableCell userProfile={profile} />
                </Link>
            ) : (
                <NameTableCell userProfile={profile} />
            )}
            <Typography variant="caption">
                <PillContainer className="align-items-center" noGutters>
                    {teamAdmins.some((a) => a.userId === userId) && <AdminChip sx={{ ml: 1 }} />}
                    {teamPayrollAdmins.some((pa) => pa.userId === userId) && (
                        <AdminChip label="Payroll Admin" sx={{ ml: 1 }} />
                    )}
                    {canViewEnrollmentVerification && profile.areVerificationRhFilesNeeded && (
                        <Chip color="warning" label="Docs Needed" size="small" sx={{ ml: 1 }} />
                    )}
                    <NeedsFollowUpPill user={profile.user} />
                    <InvalidEmailPill user={profile.user} />
                    {canViewVip && isVip && <VipChip sx={{ ml: 1 }} />}
                </PillContainer>
            </Typography>
        </React.Fragment>
    );
};

export const InfoCell = ({ row: { original } }: Cell<IUserProfile, UserInfoStatus>) => (
    <UserInfoTableCell user={original.user} />
);

export const EmailCell = ({ value }: Cell<IUserProfile, string>) => (
    <span data-cy="email">{value}</span>
);

export const LoginCreatedDateCell = ({ row: { original } }: Cell<IUserProfile>) => (
    <span data-cy="login-created-date">
        {formatDateForDisplay(original.loginCreatedDate) ?? ''}
    </span>
);

export const StatusCell = ({ value }: Cell<IUserProfile, string>) => (
    <span data-cy="user-status">{hasValue(value) ? value : ''}</span>
);

export const EnrollmentStatusCell = ({ value }: Cell<IUserProfile, string>) => (
    <span data-cy="enrollment-status">{hasValue(value) ? value : ''}</span>
);

export const ActionsCell = ({ row: { original: profile } }: Cell<IUserProfile>) => (
    <ProfileActionButtons profile={profile} />
);

export const WageIncreaseHeader = () => (
    <UnderlinedContentWithTooltip title="The gross or before-tax amount given to the member.">
        Wage+
    </UnderlinedContentWithTooltip>
);

export const ReimbursementHeader = () => (
    <UnderlinedContentWithTooltip title="The maximum amount of reimbursement the employee can receive for premiums.">
        Reimbursement
    </UnderlinedContentWithTooltip>
);

export const AddedHeader = () => (
    <UnderlinedContentWithTooltip title="The date that the member was added to Remodel Health.">
        Added
    </UnderlinedContentWithTooltip>
);
