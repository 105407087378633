import { getPathwayUserProfileInfo } from 'actions/pathwayBlueprint/getPathwayUserProfileInfo';
import { editFicaRate } from 'actions/taxData/editFicaRate';
import { EditTaxData, ViewPathwayBlueprints } from 'api/generated/permissions';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import EditableProfileAttribute from 'pages/profile/EditableProfileAttribute';
import EditTaxesModal from 'pages/profile/EditTaxesModal';
import React, { useCallback, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasSomePermissions } from 'selectors';
import { isAdvisorSelector, isRhSelector } from 'selectors/role';
import { decimalToPercentageString } from 'utilities';

const TotalTaxRateAttribute = () => {
    const dispatch = useThunkDispatch();
    const { userId } = useUserProps();
    const [showTaxesModal, setShowTaxesModal] = useState(false);
    const { canEditTaxData, canViewPathwayBlueprints, isAdvisor, isRh, taxData } = useSelector(
        (state: AppStore) => ({
            canEditTaxData: hasSomePermissions(state, EditTaxData),
            canViewPathwayBlueprints: hasSomePermissions(state, ViewPathwayBlueprints),
            isAdvisor: isAdvisorSelector(state),
            isRh: isRhSelector(state),
            taxData: state.taxData,
        })
    );
    const openTaxesModal = useCallback(() => {
        setShowTaxesModal(true);
    }, [setShowTaxesModal]);
    const closeTaxesModal = useCallback(() => {
        setShowTaxesModal(false);
    }, [setShowTaxesModal]);
    const totalTaxRate = useMemo(() => {
        if (
            taxData.federalTaxRate === undefined ||
            taxData.ficaTaxRate === undefined ||
            taxData.stateTaxRate === undefined
        ) {
            return 'N/A';
        }
        const total = taxData.federalTaxRate + taxData.ficaTaxRate + taxData.stateTaxRate;
        return decimalToPercentageString(total);
    }, [taxData.federalTaxRate, taxData.ficaTaxRate, taxData.stateTaxRate]);

    const saveTaxes = useCallback(
        async (ficaTaxRate) => {
            await dispatch(editFicaRate(taxData.id, ficaTaxRate));
            if (canViewPathwayBlueprints) {
                await dispatch(getPathwayUserProfileInfo(userId, taxData.year));
            }
            setShowTaxesModal(false);
        },
        [canViewPathwayBlueprints, dispatch, taxData.id, taxData.year, userId]
    );
    return (
        <React.Fragment>
            {(isRh || isAdvisor) && (
                <EditableProfileAttribute
                    content={totalTaxRate}
                    label="Total Tax Rate"
                    onClick={openTaxesModal}
                    readonly={!canEditTaxData}
                />
            )}
            {showTaxesModal && <EditTaxesModal close={closeTaxesModal} save={saveTaxes} />}
        </React.Fragment>
    );
};

export default hot(module)(TotalTaxRateAttribute);
